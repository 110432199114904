/* eslint react/jsx-key: off */
import React from "react";
import {
  Show,
  TextField,
  DateField,
  Tab,
  TabbedShowLayout,
} from "react-admin";
import ConfigDefaultTitle from "./ConfigDefaultTitle";
import Aside from "./Aside";
import JsonField from "../AlembicJsonFields/JsonField";

const ConfigDefaultShow = ({ ...props }) => {
  return (
    <Show title={<ConfigDefaultTitle edit={false} />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="configdefaults.tab.summary">
          <TextField source="id" />
          <TextField source="scope" />
          <TextField source="config_key" />
          <TextField source="description" />
          <JsonField source="config_value" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ConfigDefaultShow;
