import React from "react";
import Typography from "@mui/material/Typography";

const ListAside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Causal Chains Summary Review List</Typography>
      <Typography variant="body2">
        This page shows a list of causal chains summaries for review. Click the
        "Review" button on the row you would like to review.
      </Typography>
    </div>
  );
};

export default ListAside;
