import { gql } from "@apollo/client";

export const ANNOTATION_LIST = gql`
  query allAnnotations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AnnotationFilter
  ) {
    items: allAnnotations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      user_id
      organization_id
      container_id
      message_type
      message
      start_date
      end_date
      created_at
      updated_at
      deleted_at
      __typename
    }
    total: _allAnnotationsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const ANNOTATION_ONE = gql`
  query Annotation($id: ID!) {
    data: Annotation(id: $id) {
      id
      user_id
      organization_id
      container_id
      message_type
      message
      start_date
      end_date
      created_at
      updated_at
      deleted_at
      __typename
    }
  }
`;

export const ANNOTATION_CREATE = gql`
  mutation createAdminAnnotation($data: AnnotationInput!) {
    createAdminAnnotation(data: $data) {
      id
      user_id
      organization_id
      container_id
      message_type
      message
      start_date
      end_date
      created_at
      updated_at
      deleted_at
      __typename
    }
  }
`;

export const ANNOTATION_UPDATE = gql`
  mutation updateAdminAnnotation($data: AnnotationUpdateInput!) {
    updateAdminAnnotation(data: $data) {
      id
      user_id
      organization_id
      container_id
      message_type
      message
      start_date
      end_date
      created_at
      updated_at
      deleted_at
      __typename
    }
  }
`;

export const ANNOTATION_DELETE = gql`
  mutation deleteAdminAnnotation($id: ID!) {
    deleteAdminAnnotation(id: $id) {
      message
      result
    }
  }
`;
