// in src/App.js
import React from "react";
// uncomment the next line and the line below to debug queries
//import { ReactQueryDevtools } from "react-query/devtools";
import { i18nProvider } from "./i18nProvider";
import client from "./middleware/apolloClient";
import schema from "./schema.json";
import buildGraphQLProvider from "ra-data-graphql-simple";
import myBuildQuery from "./dataProvider";

import authProvider from "./authProvider";
import { Login } from "./Login";
import Dashboard from "./Dashboard";

import UserCreate from "./components/User/UserCreate";
import UserList from "./components/User/UserList";
//import UserEdit from "./components/User/UserEdit";
import UserShow from "./components/User/UserShow";

import AlbLoading from "./AlbLoading";

import ConfigDefautList from "./components/ConfigDefault/ConfigDefaultList";
import ConfigDefautEdit from "./components/ConfigDefault/ConfigDefaultEdit";
import ConfigDefautShow from "./components/ConfigDefault/ConfigDefaultShow";
import ConfigDefautCreate from "./components/ConfigDefault/ConfigDefaultCreate";

import ConfigOverrideList from "./components/ConfigOverride/ConfigOverrideList";
import ConfigOverrideShow from "./components/ConfigOverride/ConfigOverrideShow";
import ConfigOverrideCreate from "./components/ConfigOverride/ConfigOverrideCreate";


import ContainerList from "./components/Container/ContainerList";
import ContainerEdit from "./components/Container/ContainerEdit";
import ContainerShow from "./components/Container/ContainerShow";

import ECDConfigEdit from "./components/ECDConfig/ECDConfigEdit";
import ECDConfigShow from "./components/ECDConfig/ECDConfigShow";
import ECDConfigCreate from "./components/ECDConfig/ECDConfigCreate";

import LinktokenList from "./components/Linktoken/LinktokenList";
import LinktokenShow from "./components/Linktoken/LinktokenShow";
import LinktokenEdit from "./components/Linktoken/LinktokenEdit";

import AuditEntryList from "./components/AuditEntry/AuditEntryList";
import AuditEntryShow from "./components/AuditEntry/AuditEntryShow";

import OrgList from "./components/Org/OrgList";
import OrgShow from "./components/Org/OrgShow";
import OrgEdit from "./components/Org/OrgEdit";

import AnnotationCreate from "./components/Annotation/AnnotationCreate";
import AnnotationList from "./components/Annotation/AnnotationList";
import AnnotationShow from "./components/Annotation/AnnotationShow";
import AnnotationEdit from "./components/Annotation/AnnotationEdit";

import NotificationCreate from "./components/Notification/NotificationCreate";
import NotificationList from "./components/Notification/NotificationList";
import NotificationShow from "./components/Notification/NotificationShow";
import NotificationEdit from "./components/Notification/NotificationEdit";

import ClassificationEventCreate from "./components/ClassificationEvent/ClassificationEventCreate";
import ClassificationEventShow from "./components/ClassificationEvent/ClassificationEventShow";
import ClassificationEventEdit from "./components/ClassificationEvent/ClassificationEventEdit";

import PromptTemplateCreate from "./components/PromptTemplate/PromptTemplateCreate";
import PromptTemplateList from "./components/PromptTemplate/PromptTemplateList";
import PromptTemplateShow from "./components/PromptTemplate/PromptTemplateShow";
import PromptTemplateEdit from "./components/PromptTemplate/PromptTemplateEdit";

import CausalChainsSummaryAllList from "./components/CausalChainsSummaryAll/CausalChainsSummaryAllList";
import CausalChainsSummaryAllEdit from "./components/CausalChainsSummaryAll/CausalChainsSummaryAllEdit";
import { defaultDarkTheme } from "react-admin";

const darkTheme = {
  ...defaultDarkTheme,
  palette: {
    ...defaultDarkTheme.palette,
    primary: {
      main: "#90caf9",
    },
  },
};

import {
  Admin,
  Resource,
  defaultTheme,
  Layout,
  AppBar,
  ToggleThemeButton,
} from "react-admin";

import { Box, Typography } from "@mui/material";

const AlembicAppBar = (props) => (
  <AppBar {...props} toolbar={<ToggleThemeButton />}>
    <Box flex="1">
      <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Typography variant="OVERLINE">
        ALEMBIC CONFIDENTIAL - INTERNAL USE ONLY
      </Typography>
    </Box>
  </AppBar>
);

// Insert "  <ReactQueryDevtools initialIsOpen={false} />" into the block below
// statement above to enable the react-query devtools. They are incredibly
// useful when debugging queries.
const AlembicLayout = (props) => (
  <>
    <Layout {...props} appBar={AlembicAppBar} />
  </>
);

const App = () => {
  const dataProvider = buildGraphQLProvider({ // this function is from ra-data-graphql-simple
    introspection: { schema: schema.__schema },
    buildQuery: myBuildQuery, // this function populates query, variables, and and fills "data"
    client: client,
  });

  // wait for it....
  if (!dataProvider) {
    return <AlbLoading />;
  }

  return (
    <React.Fragment>
      <Admin
        disableTelemetry
        dashboard={Dashboard}
        loginPage={Login}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        layout={AlembicLayout}
        title="Alembic Admin"
        theme={defaultTheme}
        requireAuth
        darkTheme={darkTheme}
      >
        <Resource
          options={{ label: 'Annotations' }}
          name="Annotation"
          show={AnnotationShow}
          list={AnnotationList}
          create={AnnotationCreate}
          edit={AnnotationEdit}
        />
        <Resource
          options={{ label: 'Audit Log Entries' }}
          name="AuditEntry"
          show={AuditEntryShow}
          list={AuditEntryList}
        />
        <Resource
          options={{ label: 'Containers' }}
          name="Container"
          list={ContainerList}
          show={ContainerShow}
          edit={ContainerEdit}
        />
        <Resource
          options={{ label: 'ECD Configuration' }}
          name="ECDConfig"
          show={ECDConfigShow}
          create={ECDConfigCreate}
          edit={ECDConfigEdit}
        />
        <Resource name="Configdefaults"
          options={{ label: 'Config Fallbacks' }}
          list={ConfigDefautList}
          create={ConfigDefautCreate}
          edit={ConfigDefautEdit}
          show={ConfigDefautShow}
        />
        <Resource name="Configoverrides"
          options={{ label: 'Config Overrides' }}
          list={ConfigOverrideList}
          create={ConfigOverrideCreate}
          show={ConfigOverrideShow}
        />
        <Resource
          options={{ label: 'Linked Accounts' }}
          name="Linktoken"
          show={LinktokenShow}
          edit={LinktokenEdit}
          list={LinktokenList}
        />
        <Resource
          name="Notification"
          show={NotificationShow}
          list={NotificationList}
          create={NotificationCreate}
          edit={NotificationEdit}
        />
        <Resource
          name="Organization"
          list={OrgList}
          show={OrgShow}
          edit={OrgEdit}
        />
        <Resource
          name="User"
          list={UserList}
          show={UserShow}
          create={UserCreate}
        />
        <Resource
          name="PromptTemplate"
          list={PromptTemplateList}
          show={PromptTemplateShow}
          create={PromptTemplateCreate}
          edit={PromptTemplateEdit}
          options={{ label: 'Prompt Templates' }}
        />
        <Resource
          options={{ label: 'Causal Chains Approval' }}
          name="CausalChainsSummaryAll"
          list={CausalChainsSummaryAllList}
          edit={CausalChainsSummaryAllEdit}
        />
        <Resource
          options={{ label: "Classification Events" }}
          name="ClassificationEvent"
          show={ClassificationEventShow}
          create={ClassificationEventCreate}
          edit={ClassificationEventEdit}
        />
      </Admin>
    </React.Fragment>
  );
};

export default App;
