import React from "react";
import {
  SaveButton,
  Toolbar,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  Tab,
  TabbedShowLayout,
  TextInput,
  SelectInput,
  DateTimeInput,
  useRecordContext,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import MDXContent from "../MDXContent";
import Edit from "../../EditNoUndo";

import { makeStyles } from "@mui/styles";
import Aside from "./Aside";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save button only
 */
const CausalChainsSummaryAllEditToolbar = (props) => {
  const classes = useToolbarStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleSave = (data) => {
    notify(`Saved successfully with status: ${data.status}`, {
      type: "success",
    });
    redirect("list", "CausalChainsSummaryAll");
  };

  const handleFailure = (error) => {
    notify(`Save failed: ${error.message}`, { type: "warning" });
  };

  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton
        type="button"
        mutationOptions={{
          onSuccess: handleSave,
          onError: handleFailure,
        }}
      />
    </Toolbar>
  );
};

/**
 * MDXContentField component to render MDX content from the record's summary.
 *
 * This component uses the useRecordContext hook to access the current record.
 * If no record is available, it returns null.
 *
 * @returns {JSX.Element|null} The MDXContent component with the record's summary or null if no record is available.
 */
const MDXContentField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <MDXContent content={record.summary || ""} />;
};

/**
 * Renders the option text for a choice in the AutocompleteInput.
 *
 * @param {Object} choice - The choice object containing the option data.
 * @param {string} choice.name - The name of the choice.
 * @param {string|number} choice.id - The ID of the choice.
 * @returns {string} The formatted string combining the name and ID of the choice.
 */
const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

/**
 * Transforms the form values before they are sent to the server.
 *
 * This function ensures that the `container_id` is set to `null` if it is not provided,
 * and includes the `status` in the returned object.
 *
 * @param {Object} values - The form values to be transformed.
 * @param {string|number} [values.container_id] - The ID of the container.
 * @param {string} values.status - The status of the record.
 * @returns {Object} The transformed values with `container_id` and `status`.
 */
const transform = (values) => {
  const { container_id, status } = values;

  return { container_id: container_id || null, status };
};

/**
 * CausalChainsSummaryAllEdit component renders the review form for Causal Chains Summary.
 *
 * This component uses the `Edit` component from react-admin to provide a review view.
 * It includes a custom toolbar, a form with various input fields, and a tabbed layout.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.id - The ID of the record to be edited.
 * @param {Object} props.resource - The resource name.
 * @param {Object} props.basePath - The base path for the resource.
 * @param {Object} props.permissions - The permissions for the resource.
 * @returns {JSX.Element} The rendered component.
 */
const CausalChainsSummaryAllEdit = (props) => {
  return (
    <Edit
      {...props}
      transform={transform}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: (data) => {
          notify(`Saved successfully with status: ${data.status}`, {
            type: "success",
          });
        },
        onError: (error) => {
          notify(`Save failed: ${error.message}`, { type: "warning" });
        },
      }}
      aside={<Aside />}
    >
      <TabbedShowLayout>
        <Tab label="Causal Chains Summary Review">
          <SimpleForm toolbar={<CausalChainsSummaryAllEditToolbar />}>
            <ReferenceInput
              disabled
              label="Container"
              source="container_id"
              reference="Container"
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput
                disabled
                sx={{ width: "400px" }}
                optionText={optionRenderer}
              />
            </ReferenceInput>

            <TextInput disabled label="ID" source="id" />
            <DateTimeInput disabled label="Created At" source="created_at" />
            <TextInput disabled label="Periodical" source="periodical" />
            <MDXContentField />
            <SelectInput
              label="Status"
              source="status"
              choices={[
                { id: "pending", name: "Pending" },
                { id: "approved", name: "Approved" },
                { id: "rejected", name: "Rejected" },
              ]}
              defaultValue={(record) => record.status}
            />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};

export default CausalChainsSummaryAllEdit;
