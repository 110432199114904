import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import {
  SimpleForm, TextInput, useRecordContext, useCreate, Toolbar, SaveButton, required, useNotify, useRedirect
} from "react-admin";

import JsonInput from "../AlembicJsonFields/JsonInput";
import JsonField from "../AlembicJsonFields/JsonField";
import ConfigDefaultScopeChoices from "./ConfigDefaultScopeChoices";

const ConfigDefaultScopesNoGlobal = ConfigDefaultScopeChoices.filter((scope) => scope.id !== 'global');

const NoDeleteToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const OverrideButtonWithDialog = (props) => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [create, { data, error, loading }] = useCreate('Configoverrides');
  const notify = useNotify();
  const redirect = useRedirect();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    // tbd here
    const newRecord = {
      reason: data.reason,
      scope: data.scope,
      scope_id: data.scope_id,
      scope: data.scope,
      config_key: data.config_key,
      config_value: data.config_new_value
    };
    try {
      await create('Configoverrides', { data: newRecord },
        {
          onSuccess: (response) => {
            // Handle success, e.g., redirect to the list view
            notify('Override created', { type: 'success' });
            setOpen(false);
            redirect("list", "Configoverrides");
          },
          onError: (error) => {
            // Handle error
            console.error('Error creating post:', error);
            notify(`Error: ${error.message}`, { type: 'error' });
          }
        });
    } catch (error) {
      console.error('Error creating post:', error);
      notify('Failed to create override.', { type: 'error' });
    }
  }

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {props.label ? props.label : "Override"}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>Create Override for <tt>{record.scope}:{record.config_key}</tt></DialogTitle>
        <DialogContent>
          <Typography variant="body">
            Please provide a scope ID, and enter a new value to override the default value.
          </Typography>
          <br />
          <Typography variant="body">
            Any change made here will create a new version, replacing the default value for this scope and key.
          </Typography>
          <SimpleForm onSubmit={onSubmit} toolbar={<NoDeleteToolbar />}>
            {!props.replaceMode && (
              <>
                <Typography variant="caption">
                  Description
                </Typography>
                <Typography variant="body" color="secondary">
                  {record.description}
                </Typography>
              </>
            )}
            <Typography variant="caption">
              Scope
            </Typography>
            <Typography variant="body" color="secondary">
              {record.scope}
            </Typography>

            {props.replaceMode ? (
              <>
                <Typography variant="caption">
                  Scope ID
                </Typography>
                <Typography variant="body" color="secondary">
                  {record.scope_id}
                </Typography>
              </>
            ) : (
              <TextInput label="Scope ID" fullWidth source="scope_id" validate={required()} />
            )}
            <TextInput label="Reason for this change" fullWidth source="reason" validate={required()} />

            <Typography variant="caption">
              Current Value
            </Typography>
            <JsonField fullWidth source="config_value" />
            <br />
            <JsonInput source="config_new_value" defaultValue={record.config_value} isRequired />
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );
};


OverrideButtonWithDialog.propTypes = {
  replaceMode: PropTypes.bool,
};

OverrideButtonWithDialog.defaultProps = {
  replaceMode: false,
};

export default OverrideButtonWithDialog;
