/* eslint react/jsx-key: off */
import React from "react";

import {
  Datagrid,
  List,
  TextField,
  TextInput,
  ShowButton,
  Pagination,
  DeleteButton,
  SelectInput,
  EditButton,
  SimpleShowLayout
} from "react-admin";

import JsonField from "../AlembicJsonFields/JsonField";

import Aside from "./Aside";
import ConfigDefaultScopeChoices from "./ConfigDefaultScopeChoices";
import OverrideButtonWithDialog from "./OverrideButtonWithDialog";

const configDefaultFilters = [
  <TextInput label="Search Scope / Description" source="q" alwaysOn />,
  <SelectInput label="Scope" source="scope" defaultValue="" choices={ConfigDefaultScopeChoices} />,
  <TextInput label="Config Key" source="config_key" defaultValue="" />
];

const ConfigDefaultPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ConfigDefaultPanel = () => (
  <SimpleShowLayout>
    <JsonField source="config_value" label="Config" fullWidth />
  </SimpleShowLayout>
);

const ConfigDefaultList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={configDefaultFilters}
    sort={{
      field: "id",
      order: "ASC",
    }}
    aside={<Aside />}
    pagination={<ConfigDefaultPagination />}
  >
    <Datagrid optimized expand={<ConfigDefaultPanel />}>
      <TextField source="id" />
      <TextField source="scope" />
      <TextField source="config_key" />
      <TextField source="description" />
      <ShowButton label="Show" />
      <EditButton label="Edit" />
      <DeleteButton label="Delete" />
      <OverrideButtonWithDialog />
    </Datagrid>
  </List>
);

export default ConfigDefaultList;
