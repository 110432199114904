import PeopleIcon from "@mui/icons-material/People";
import React from "react";
import { hasAccess } from "ra-auth-acl";

import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  DateField,
  ReferenceField,
  ShowButton,
  Pagination,
} from "react-admin";

import Aside from "./Aside";
import BecomeButton from "./BecomeButton";

export const UserIcon = PeopleIcon;

const UserFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const UserBulkActionButtons = (props) => {
  const { permissions } = props;

  return (
    <div>
      {hasAccess(permissions, "User.delete") && (
        <BulkDeleteWithConfirmButton {...props} />
      )}
    </div>
  );
};

const UserPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const UserList = ({ permissions, ...props }) => (
  <List
    {...props}
    sort={{ field: "last_name", order: "ASC" }}
    aside={<Aside />}
    filters={<UserFilter permissions={permissions} />}
    pagination={<UserPagination />}
  >
    <Datagrid
      bulkActionButtons={<UserBulkActionButtons permissions={permissions} />}
      optimized>
      <TextField source="id" />
      <ReferenceField
        label="Organization"
        source="current_organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="role.name" label="Org Role" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="email" />
      <TextField source="phone" />
      <TextField source="account_state" />
      <DateField source="last_access_time" showTime />
      <ShowButton label="Show" />
      <BecomeButton source="actions" />
    </Datagrid>
  </List>
);

export default UserList;
