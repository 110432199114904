/* eslint react/jsx-key: off */
import React from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  DateField,
  ShowButton,
  EditButton,
  ChipField,
  ReferenceField,
  Pagination,
} from "react-admin";

import FullNameField from "../FullNameField";

import Aside from "./Aside";

const OrgFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const OrgBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const OrgPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const OrgList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<OrgFilter permissions={permissions} />}
    sort={{ field: "name", order: "ASC" }}
    aside={<Aside />}
    bulkActionButtons={<OrgBulkActionButtons />}
    pagination={<OrgPagination />}
  >
    <Datagrid optimized>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="created_at" showTime />
      <ChipField source="account_type" />
      <ChipField source="customer_type" />
      <ReferenceField
        label="Account Manager"
        source="account_manager_user_id"
        reference="User"
        link="show"
      >
        <FullNameField />
      </ReferenceField>
      <ShowButton label="Show" />
      <EditButton label="Edit" />
    </Datagrid>
  </List>
);

export default OrgList;
