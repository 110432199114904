import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Linktokens</Typography>
      <Typography variant="body2">
        Linktokens represent links between external (OAuth2) services and
        Alembic.
      </Typography>
    </div>
  );
};

export default Aside;
