import { gql } from "@apollo/client";
import apolloClientHelper from "../middleware/api";

const INITIAL_USER_AUTH = `
  id
  avatar_url
  first_name
  last_name
  email
  phone
  company
  title
  bio
  time_zone
  setup_complete
  force_password_change
  otp_enabled
  current_organization_id
  last_access_time
  created_at
  updated_at
  eula_accepted_at
  current_organization {
    id
    name
  }
  home_container_id
  home_container {
    id
    name
  }
  privileges {
    short_name
  }
`;

export const GET_USER = gql`
  query ($id: ID!) {
    User(id: $id) {
      id
      first_name
      last_name
      current_organization_id
      email
      phone
      avatar_url
      company
      title
      bio
      time_zone
      setup_cmplete
      force_password_change
      otp_enabled
      home_container_id
      eula_accepted_at
      created_at
      updated_at
      last_access_time
      role {
        id
        name
      }
      preferences {
        key
        value_i
        value_s
      }
    }
  }
`;

export const GET_ME = gql`
  query {
    me {
      user {
        ${INITIAL_USER_AUTH}
      }
      session {
        id
        user_id
        mfa_passed
        mfa_required
        login_at
        expires
      }
    }
  }
`;

export const USER_SIGN_IN = gql`
  mutation ($emailOrPhone: String!, $password: String!) {
    signIn(password: $password, emailOrPhone: $emailOrPhone) {
      user {
        ${INITIAL_USER_AUTH}
      }
      otpRequired
      expires
      token
    }
  }
`;

export const USER_SIGN_OUT = gql`
  mutation {
    signOut {
      message
      result
    }
  }
`;

export async function apiUserSignOut() {
  return apolloClientHelper(
    USER_SIGN_OUT,
    {},
    true // mutation
  );
}

export const USER_AUTH_OTP = gql`
  mutation ($code: String!, $rememberDevice: Boolean!) {
    authOTP(code: $code, rememberDevice: $rememberDevice) {
      user {
        ${INITIAL_USER_AUTH}
      }
      expires
      token
    }
  }
`;

export const CREATE_USER = gql`
  mutation (
    $first_name: String!
    $last_name: String!
    $company: String!
    $password: String!
    $phone: String!
    $email: String!
    $time_zone: String!
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      company: $company
      password: $password
      email: $email
      phone: $phone
      time_zone: $time_zone
    ) {
      user {
        id
        setup_complete
        company
      }
      expires
    }
  }
`;

export const USER_RECOVER = gql`
  mutation ($emailOrPhone: String!) {
    recoverUser(emailOrPhone: $emailOrPhone) {
      message
      result
    }
  }
`;

export const USER_RECOVER_CHECK = gql`
  mutation ($token: String!) {
    recoverWithToken(token: $token) {
      error
      result
      expires
      user {
        id
        first_name
        last_name
        bio
        title
        company
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        setup_complete
        otp_enabled
        home_container_id
        current_organization_id
        force_password_change
        created_at
        updated_at
        avatar_url
        privileges {
          short_name
        }
      }
    }
  }
`;

export const USER_RECOVER_EMAIL = gql`
  query ($token: String!) {
    recoverEmailToken(token: $token)
  }
`;

export const USER_CHANGE_PASSWORD = gql`
  mutation ($old_password: String, $password: String!) {
    changeMyPassword(old_password: $old_password, password: $password) {
      message
      result
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation ($token: String!, $password: String!) {
    resetMyPassword(token: $token, password: $password) {
      message
      result
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation (
    $first_name: String!
    $last_name: String!
    $company: String!
    $phone: String!
    $time_zone: String!
    $bio: String
    $title: String
  ) {
    updateUserProfile(
      first_name: $first_name
      last_name: $last_name
      bio: $bio
      title: $title
      company: $company
      phone: $phone
      time_zone: $time_zone
    ) {
      error
      result
      user {
        id
        first_name
        last_name
        company
        bio
        title
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        force_password_change
        setup_complete
        created_at
        updated_at
        organizations {
          id
          name
          org_uuid
        }
        containers {
          id
          name
          parent_id
          organization_id
        }
        current_organization {
          id
          name
        }
        home_container {
          id
          name
        }
        setup_complete
        home_container_id
        current_organization_id
        avatar_url
      }
    }
  }
`;

export const UPDATE_USER_AVATAR = gql`
  mutation ($handle: String!) {
    updateUserAvatar(handle: $handle) {
      error
      result
      user {
        id
        first_name
        last_name
        company
        bio
        title
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        force_password_change
        setup_complete
        otp_enabled
        created_at
        updated_at
        organizations {
          id
          name
          org_uuid
        }
        containers {
          id
          name
          parent_id
          organization_id
        }
        current_organization {
          id
          name
        }
        setup_complete
        home_container_id
        home_container {
          id
          name
        }
        current_organization_id
        avatar_url
      }
    }
  }
`;

export const VERIFY_CONFIRMATION_CODE = gql`
  mutation ($code: String!) {
    confirmPhone(code: $code) {
      message
      result
    }
  }
`;

export const RESEND_CONFIRMATION_CODE = gql`
  mutation ($id: ID) {
    resendPhoneConfirmation(id: $id) {
      message
      result
    }
  }
`;

export async function apiUserResendConfirmation(id) {
  return apolloClientHelper(
    RESEND_CONFIRMATION_CODE,
    { id }, // A user ID is optional here; if not passed in, the confirmation code is re-sent to the current user
    true // mutation
  );
}

const USER_SEND_SMS = gql`
  mutation ($userIds: [String]!, $message: String!) {
    sendSMS(userIds: $userIds, message: $message) {
      message
      result
    }
  }
`;

export async function apiUserSendSMS(userIds, message) {
  return apolloClientHelper(
    USER_SEND_SMS,
    { userIds, message },
    true // mutation
  );
}

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $first_name: String
    $last_name: String
    $phone: String!
    $title: String
  ) {
    updateUser(
      id: $id
      first_name: $first_name
      last_name: $last_name
      title: $title
      phone: $phone
    ) {
      error
      result
      user {
        id
        first_name
        last_name
        title
        phone
        phone_confirmed
      }
    }
  }
`;

export async function apiUserUpdate(data) {
  return apolloClientHelper(
    UPDATE_USER,
    {
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      bio: data.bio,
      title: data.title,
      company: data.company,
      phone: data.phone,
      time_zone: data.time_zone,
    },
    true // mutation
  );
}

const CREATE_USER_BY_PHONE = gql`
  mutation (
    $first_name: String!
    $last_name: String!
    $title: String
    $phone: String!
    $existing_container_id: ID
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      title: $title
      phone: $phone
      existing_container_id: $existing_container_id
    ) {
      user {
        id
        first_name
        last_name
        title
        phone
        phone_confirmed
        email
        email_verified
        time_zone
        force_password_change
        setup_complete
        home_container_id
        current_organization_id
        created_at
        updated_at
        avatar_url
      }
      expires
    }
  }
`;

export async function apiUserRegisterByPhone(data) {
  return apolloClientHelper(
    CREATE_USER_BY_PHONE,
    {
      first_name: data.first_name,
      last_name: data.last_name,
      title: data.title,
      phone: data.phone,
      existing_container_id: data.existing_container_id,
    },
    true // mutation
  );
}

export const UPDATE_USER_PREFERENCE = gql`
  mutation updateUserPreference(
    $key: String!
    $value_i: Int
    $value_s: String
  ) {
    updateUserPreference(key: $key, value_i: $value_i, value_s: $value_s) {
      id
      key
      value_i
      value_s
      created_at
      updated_at
    }
  }
`;

export async function apiUpdateUserPrefs(data) {
  return apolloClientHelper(UPDATE_USER_PREFERENCE, data, true);
}

export const GET_USER_FEATURE_FLAGS = gql`
  query getFeatureFlagsForUser {
    getFeatureFlagsForUser {
      name
      enabled
    }
  }
`;

export async function apiGetUserFeatureFlags(data) {
  return apolloClientHelper(GET_USER_FEATURE_FLAGS, data, false);
}

export const USER_CREATE = gql`
  mutation createUserOnOrganization(
    $first_name: String!,
    $last_name: String!,
    $phone: String,
    $email: String!,
    $time_zone: String!,
    $password: String!,
    $title: String,
    $organization_id: ID!,
    $home_container_id: ID!,
    $role_id: ID!
  ) {
    createUserOnOrganization(
      first_name: $first_name,
      last_name: $last_name,
      phone: $phone,
      email: $email,
      time_zone: $time_zone,
      password: $password,
      title: $title,
      organization_id: $organization_id,
      home_container_id: $home_container_id,
      role_id: $role_id
    ) {
      id
      first_name
      last_name
      bio
      title
      company
      phone
      phone_confirmed
      email
      email_verified
      time_zone
      setup_complete
      otp_enabled
      home_container_id
      current_organization_id
      force_password_change
      created_at
      updated_at
      avatar_url
      privileges {
        short_name
      }
    }
  }
`;