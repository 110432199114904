import { gql } from "@apollo/client";

export const PROMPT_TEMPLATE_LIST = gql`
  query allPromptTemplates(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PromptTemplateFilter
  ) {
    items: allPromptTemplates(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      container_id
      stages
      is_default
      created_at
      updated_at
      __typename
    }
    total: _allPromptTemplatesMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const PROMPT_TEMPLATE_ONE = gql`
  query PromptTemplate($id: ID!) {
    data: PromptTemplate(id: $id) {
      id
      container_id
      stages
      is_default
      created_at
      updated_at
      __typename
    }
  }
`;

export const PROMPT_TEMPLATE_CREATE = gql`
  mutation createAdminPromptTemplate($data: PromptTemplateInput!) {
    createAdminPromptTemplate(data: $data) {
      id
      container_id
      stages
      is_default
      created_at
      updated_at
      __typename
    }
  }
`;

export const PROMPT_TEMPLATE_UPDATE = gql`
  mutation updateAdminPromptTemplate($data: PromptTemplateUpdateInput!) {
    updateAdminPromptTemplate(data: $data) {
      id
      container_id
      stages
      is_default
      created_at
      updated_at
      __typename
    }
  }
`;