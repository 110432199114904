import { gql } from "@apollo/client";


export const CONFIGDEFAULT_ONE = gql`
  query ConfigDefault($id: ID!) {
    ConfigDefault(id: $id) {
      id
      config_key
      config_value
      scope
      created_at
      updated_at
      description
      __typename
    }
  }
`;

export const CONFIGDEFAULT_LIST = gql`
  query allConfigDefaults(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ConfigDefaultsFilter
  ) {
    items: allConfigDefaults(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      config_key
      config_value
      scope
      created_at
      updated_at
      description
      __typename
    }
    total: _allConfigDefaultsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const CONFIGDEFAULT_CREATE = gql`
  mutation CreateConfigDefault($data: ConfigDefaultCreateInput!) {
   createConfigDefault(data: $data) {
      id
      config_key
      config_value
      scope
      created_at
      updated_at
      description
      __typename
    }
  }
`;

export const CONFIGDEFAULT_UPDATE = gql`
  mutation updateConfigDefault($data: ConfigDefaultUpdateInput!) {
   updateConfigDefault(data: $data) {
      id
      config_key
      config_value
      scope
      created_at
      updated_at
      description
      __typename
    }
  }
`;

export const CONFIGDEFAULT_DELETE = gql`
  mutation DeleteConfigDefault($id: ID!) {
    data: deleteConfigDefault(id: $id) {
      id
      config_key
      config_value
      scope
      created_at
      updated_at
      description
      __typename
    }
  }
`;