/* eslint react/jsx-key: off */
import React from "react";

import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  ReferenceField,
} from "react-admin";

import JsonField from "../AlembicJsonFields/JsonField";

import AuditEntryTitle from "./AuditEntryTitle";
import Aside from "./Aside";

import FromNowDateField from "../FromNowDateField";

const AuditEntryShow = ({ ...props }) => (
  <Show title={<AuditEntryTitle show />} aside={<Aside />} {...props}>
    <TabbedShowLayout>
      <Tab label="audit_entry.tab.summary">
        <TextField source="id" />
        <FromNowDateField label="Created At" source="created_at" showTime />
        <TextField source="user_id" />
        <TextField source="action" />
        <TextField source="model" />
        <ReferenceField
          label="Organization"
          source="organization_id"
          reference="Organization"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Container"
          source="container_id"
          reference="Container"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <JsonField source="meta" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default AuditEntryShow;
