import { gql } from "@apollo/client";

export const BECOME_USER = gql`
  mutation becomeUser($id: ID!) {
    becomeUser(id: $id) {
      user {
        id
      }
      error
      token
      result
    }
  }
`;
