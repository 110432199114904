import React from "react";
import { useRecordContext } from "react-admin";

const FullNameField = (props) => {
  const record = useRecordContext(props);
  return (
    <span>
      {record.first_name} {record.last_name}
    </span>
  );
};

FullNameField.defaultProps = {
  label: "Name",
  addLabel: true,
};

export default FullNameField;
