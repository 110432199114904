import { useInput } from "react-admin";
import { Autocomplete, TextField } from "@mui/material";

/**
 * A react component for dynamic options auto complete options
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The AutocompleteInput component.
 */
const AutocompleteInput = ({
  label,
  source,
  choices,
  optionText,
  optionValue,
  ...rest
}) => {
  // see https://marmelab.com/react-admin/Upgrade.html#useinput-signature-and-return-value-have-changed
  // lots of old (bad) implementation samples out there to get binding to useController to work
  const { field } = useInput({ source });

  return (
    <Autocomplete
      options={choices}
      getOptionLabel={(option) => option[optionText]}
      onChange={(event, value) => {
        // binds optionValue property to the form
        field.onChange(value[optionValue]);
      }}
      renderInput={(params) => (
        <TextField {...params} required label={label} variant="filled" />
      )}
      {...rest}
    />
  );
};

export default AutocompleteInput;
