<DateField source="last_poll_time" showTime />;
/* eslint react/jsx-key: off */
import React from "react";
import IdAndNameField from "../IdAndNameField";
import MoveButton from "../MoveButton";
import RenameContainerButton from "../RenameContainerButton";
import BecomeButton from "../User/BecomeButton";

import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  DateField,
  ChipField,
  ReferenceField,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ShowButton,
} from "react-admin";

import OrgTitle from "./OrgTitle";
import Aside from "./Aside";
import FullNameField from "../FullNameField";

const OrgShow = ({ ...props }) => {
  return (
    <Show title={<OrgTitle show />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="org.tab.summary">
          <TextField source="name" />
          <TextField source="company_short_name" />
          <TextField source="avatar_handle" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <TextField source="id" />
          <ReferenceField
            label="Owner"
            source="user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>
          <ReferenceField
            label="Account Manager"
            source="account_manager_user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>
          <TextField source="org_uuid" />
          <NumberField source="business_type_id" />
          <NumberField source="structure_type_id" />
          <NumberField
            source="max_containers"
            label="Max Containers (-1 = infinite)"
          />
          <NumberField source="max_users" label="Max Users (-1 = infinite)" />
        </Tab>
        <Tab label="org.tab.business">
          <NumberField source="week_start" />
          <NumberField source="year_start" />
          <ChipField source="account_type" />
          <ChipField source="customer_type" />
        </Tab>
        <Tab label="org.tab.security">
          <BooleanField source="mfa_mandatory" />
          <DateField source="mfa_on_from" showTime />
          <NumberField source="mfa_new_user_grace_days" />
          <BooleanField source="mfa_permit_device_trust" />
          <NumberField source="mfa_exception_group" />
          <TextField source="mfa_methods" />
          <NumberField source="mfa_key_grace_period" />
          <TextField source="mfa_security_code_policy" />
        </Tab>
        <Tab label="org.tab.containers">
          <ReferenceManyField label="Containers" reference="Container" target="organizationId"
            pagination={<Pagination />}
            rowsPerPageOptions={[10, 25, 50, 100]}
            perPage={50}>
            <Datagrid>
              <ReferenceField
                label="Container ID"
                source="id"
                reference="Container"
                link="show"
              >
                <TextField source="id" />
              </ReferenceField>
              <ReferenceField
                label="Container Name"
                source="id"
                reference="Container"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Parent Container"
                source="parent_id"
                reference="Container"
                link="show"
              >
                <IdAndNameField />
              </ReferenceField>

              <RenameContainerButton label="Rename" />
              <MoveButton label="Change Parent" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="org.tab.linktokens">
          <ReferenceManyField
            reference="Linktoken"
            target="organization_id"
            label=""
            pagination={<Pagination />}
            rowsPerPageOptions={[10, 25, 50, 100]}
            perPage={10}
          >
            <Datagrid>
              <TextField source="id" />
              <DateField source="created_at" showTime />

              <DateField source="last_poll_time" showTime />

              <ReferenceField
                label="Container"
                source="container_id"
                reference="Container"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="User"
                source="user_id"
                reference="User"
                link="show"
              >
                <FullNameField />
              </ReferenceField>
              <TextField source="type" />
              <TextField source="remote_name" />
              <TextField source="remote_id" />

              <BooleanField source="deleted" />
              <BooleanField source="enabled" />
              <BooleanField source="valid_token" />

              <ShowButton label="Show" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="org.tab.users">
          <ReferenceManyField label="Users" reference="User" target="organizationId"
            pagination={<Pagination />}
            rowsPerPageOptions={[10, 25, 50, 100]}
            perPage={50}>
            <Datagrid>
              <TextField source="id" />
              <TextField source="first_name" />
              <TextField source="last_name" />
              <TextField source="role.name" label="Org Role" />
              <TextField source="email" />
              <TextField source="phone" />
              <ShowButton label="Show" />
              <BecomeButton source="actions" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
export default OrgShow;
