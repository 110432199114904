import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Containers</Typography>
      <Typography variant="body2">
        Containers are the primary unit of account management in Alembic. They
        contain groups of Linked Accounts (linktokens). Containers are also used
        to control correlation between accounts (ECD).
      </Typography>
    </div>
  );
};

export default Aside;
