import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  DateField,
  TextField,
  BooleanField,
  ReferenceField,
} from "react-admin";

import PromptTemplateTitle from "./PromptTemplateTitle";

import JsonField from "../AlembicJsonFields/JsonField";

const PromptTemplateShow = (props) => (
  <Show
    {...props}
    title={<PromptTemplateTitle />}
    // aside={<Aside />}
  >
    <TabbedShowLayout>
      <Tab label="prompt_template.tab.summary">
        <TextField source="id" />
        <ReferenceField
          label="Container"
          source="container_id"
          reference="Container"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_default" />
        <JsonField source="stages" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default PromptTemplateShow;
