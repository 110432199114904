
import React from 'react';
import { useRecordContext, useEditContext, useTranslate } from 'react-admin';


const ConfigDefaultTitle = (props) => {
  const record = useRecordContext();
  const { isLoading } = useEditContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return (
    <span>
      {record
        ? translate("config_defaults.edit.title", {
          id: record.id,
          message: record.message,
        })
        : ""}
    </span>
  );
};

export default ConfigDefaultTitle;

