/* eslint react/jsx-key: off */
import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  DateField,
  BooleanField,
  NumberField,
  ReferenceField,
} from "react-admin";

import IdAndNameField from "../IdAndNameField";
import ECDConfigTitle from "./ECDConfigTitle";
import Aside from "./Aside";
import JsonField from "../AlembicJsonFields/JsonField";

const ECDConfigShow = ({ ...props }) => {
  const TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE = 2.7;
  return (
    <Show title={<ECDConfigTitle edit={false} />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="ecdconfig.form.summary">
          <TextField source="id" disabled />
          <ReferenceField
            label="Container"
            source="container_id"
            reference="Container"
            link="show"
          >
            <IdAndNameField source="id" />
          </ReferenceField>
          <DateField source="created_at" showTime disabled />
          <DateField source="updated_at" showTime disabled />{" "}
          <ReferenceField
            label="Container Last Poll Time"
            source="container_id"
            reference="Container"
            link="show"
          >
            <DateField source="last_poll_time" showTime />
          </ReferenceField>
          <ReferenceField
            label="Container Next Poll Time"
            source="container_id"
            reference="Container"
            link="show"
          >
            <DateField source="next_poll_time" showTime />
          </ReferenceField>
          <DateField
            source="global_start_date"
            label="Start date for ECD"
            defaultValue="2021/12/01"
          />
        </Tab>
        <Tab label="ecdconfig.form.subsystems">
          <BooleanField
            source="enable_ga_events"
            label="Include Google Analytics in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_aa_events"
            label="Include Adobe Analytics in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_broadcasts_events"
            label="Include Broadcasts in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_podcasts_events"
            label="Include Podcasts in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_social_animal_events"
            label="Include Social Animal in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_impressions_events"
            label="Include Impression Events in ECD"
            defaultValue
          />
          <BooleanField
            source="enable_coincidence_events"
            label="Run Concidence Detections"
            defaultValue
          />
        </Tab>
        <Tab label="ecdconfig.form.peakconfig">
          <NumberField
            source="peak_event_min_magnitude"
            label="Peak Detector Min Magnitude (default)"
            fullWidth
          />
          <JsonField
            source="peak_event_min_magnitude_dict"
            label="Peak Detector Min Magnitude Overrides"
            fullWidth
          />
        </Tab>
        <Tab label="ecdconfig.form.tsmoothiesigmaconfig">
          <NumberField
            source="tsmoothie_sigma"
            label="Tsmoothie Sigma Event Config (default)"
            defaultValue={TSMOOTHIE_SIGMA_EVENT_DEFAULT_VALUE}
          />
          <JsonField
            source="tsmoothie_sigma_event_override"
            label="Tsmoothie Sigma Event Overrides"
            fullWidth
          />
        </Tab>
        <Tab label="ecdconfig.form.cdconfig">
          <NumberField
            source="cd_min_rel_rank_default"
            label="Coincidence detection MRR (default)"
            fullWidth
          />
          <JsonField
            source="cd_min_rel_rank_dict"
            label="Concidence Detector MRR Overrides"
            fullWidth
          />
        </Tab>
        <Tab label="ecdconfig.form.gaconfig">
          <TextField source="ga_product_name_replace_pattern" fullWidth />
          <TextField source="ga_item_name_replace_pattern" fullWidth />
          <NumberField source="ga_min_metric_sum_all_time" fullWidth />
          <NumberField source="ga_metric_pct_of_total_cutoff" fullWidth />
          <NumberField source="ga_max_time_series_count" fullWidth />
          <NumberField source="aa_min_metric_sum_all_time" fullWidth />
          <NumberField source="aa_metric_pct_of_total_cutoff" fullWidth />
          <NumberField source="aa_max_time_series_count" fullWidth />
        </Tab>
        <Tab label="ecdconfig.form.advanced">
          <NumberField source="max_duration_days" defaultValue={90} />
          <NumberField source="peak_win_size" defaultValue={10} />
          <NumberField source="peak_cutoff_th" defaultValue={0.8} />
          <NumberField source="mag_base_look_back_days" defaultValue={30} />
          <NumberField source="min_daily_initial_group_size" defaultValue={4} />
          <NumberField source="cd_match_norm_threshold" defaultValue={0.5} />
          <NumberField
            source="cd_split_threshold_days"
            step={1}
            defaultValue={10000}
          />
          <NumberField
            source="cd_split_sensitivity"
            step={1}
            defaultValue={3}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ECDConfigShow;
