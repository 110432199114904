/**
 * @file Classification events GQLs for React Admin
 */

import { gql } from "@apollo/client";

/**
 * Lists and filter classification events
 */
export const CLASSIFICATION_EVENT_LIST = gql`
  query allClassificationEvents(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ClassificationEventFilter
  ) {
    items: allClassificationEvents(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      linked_account_id
      goal_id
      event_name
      event_value
      classification
      __typename
    }
    total: _allClassificationEventsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

/**
 * Get a classification event
 */
export const CLASSIFICATION_EVENT_ONE = gql`
  query ClassificationEvent($id: ID!) {
    data: ClassificationEvent(id: $id) {
      id
      linked_account_id
      goal_id
      event_name
      event_value
      classification
      __typename
    }
  }
`;

/**
 * Creates new classification event
 */
export const CLASSIFICATION_EVENT_CREATE = gql`
  mutation createAdminClassificationEvent($data: ClassificationEventInput!) {
    createAdminClassificationEvent(data: $data) {
      id
      linked_account_id
      goal_id
      event_name
      event_value
      classification
      __typename
    }
  }
`;

/**
 * Updates a classification event
 */
export const CLASSIFICATION_EVENT_UPDATE = gql`
  mutation updateAdminClassificationEvent(
    $data: ClassificationEventUpdateInput!
  ) {
    updateAdminClassificationEvent(data: $data) {
      id
      linked_account_id
      goal_id
      event_name
      event_value
      classification
      __typename
    }
  }
`;

/**
 * Deletes a classification event
 */
export const CLASSIFICATION_EVENT_DELETE = gql`
  mutation deleteAdminClassificationEvent($id: ID!) {
    deleteAdminClassificationEvent(id: $id) {
      message
      result
    }
  }
`;

export const SEARCH_EVENT_NAMES = gql`
  query searchEventNames($query: String, $linktoken_id: ID!) {
    searchEventNames(query: $query, linktoken_id: $linktoken_id) {
      goal_id
      name
      event_name
      event_value
      __typename
    }
  }
`;
