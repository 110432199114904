import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Aside = () => (
  <div style={{ width: 200, margin: "1em" }}>
    <Typography variant="h6">Classification Events</Typography>

    <Typography variant="body2">
      GA3 goals, GA4 events, and Adobe Analytics evars (conversion variables)
      can be sub classified as CONVERSION (default) or INTERACTION types.
    </Typography>
    <p />
  </div>
);

export default Aside;
