import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { SESSION_NAME } from "../../authProvider";
import {
  Button,
  useRecordContext,
  useDataProvider
} from "react-admin";

import { useMutation } from 'react-query';

/**
 * Returns a button that can be used to become another user.
 */
const BecomeButton = (props) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const { mutate, isLoading } = useMutation(
    ['Become', 'update', { id: record.id, data: { id: record.id } }],
    () =>
      dataProvider.update('Become', { id: record.id, data: { id: record.id } }),
    {
      onSuccess: (data) => {
        console.log(data);
        // log them out of the admin system
        localStorage.removeItem(SESSION_NAME);

        // redirect them into the main app, with the token.
        window.location.href = `${process.env.REACT_APP_WEB_URL}/users/become?tok=${data.data.token}`;

      }
    }
  );

  // if the user is disabled we can't show the button.
  if (record.account_state !== 'REGISTERED') {
    return null;
  }

  return (
    <Button
      label="Become"
      onClick={() => mutate()}
      disabled={isLoading}
    >
      <ChangeCircleIcon />
    </Button>
  );

};

export default BecomeButton;

