/* eslint react/jsx-key: off */
import React from "react";
import {
  Show,
  TextField,
  DateField,
  Tab,
  TabbedShowLayout,
  useTranslate
} from "react-admin";
import ConfigOverrideTitle from "./ConfigOverrideTitle";
import Aside from "./Aside";
import JsonField from "../AlembicJsonFields/JsonField";

const ConfigOverrideShow = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Show title={<ConfigOverrideTitle edit={false} />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label={translate("config_overrides.tab.summary")}>
          <TextField source="id" />
          <TextField source="version" />
          <TextField source="reason" />
          <TextField source="scope" />
          <TextField source="scope_id" />
          <TextField source="config_key" />
          <JsonField source="config_value" />
          <DateField showTime source="created_at" />
          <DateField showTime source="updated_at" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ConfigOverrideShow;
