import React from "react";

import { useRecordContext, DateField } from "react-admin";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

function timeAgo(input) {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat("en");
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;

  // eslint-disable-next-line no-restricted-syntax
  for (const key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(Math.round(delta), key);
    }
  }
}

const FromNowDateField = (props) => {
  const record = useRecordContext(props);
  const { source, label, ...rest } = props;

  // I can't figure out wtf the style issue here
  // so I'm hardcoding it.

  const labelStyle = {
    color: "rgba(0, 0, 0, 0.54)",
    padding: 0,
    fontSize: "0.70em",
    fontWeight: "400",
    lineHeight: 1,
    letterSpacing: "0.15008px",
  };

  return (
    <dl>
      {record[source] && (
        <dt>
          <DateField record={record} source={source} {...rest} />
          <Typography component="span" variant="body2">
            &nbsp;({timeAgo(record[source])})
          </Typography>
        </dt>
      )}
      {!record[source] && <dt>Never</dt>}
    </dl>
  );
};

FromNowDateField.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default FromNowDateField;
