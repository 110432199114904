import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Configuration Overrides</Typography>
      <Typography variant="body1">
        These overrides set different configurations for a particular scope
        and scope ID.
      </Typography>
      <br />
      <Typography variant="body1">
        Overrides can only be setup after the default settings have been saved.
      </Typography>
      <br />
      <Typography variant="body1">
        New overrides can only be created for entries which have existing default
        settings.
      </Typography>
    </div>
  );
};

export default Aside;
