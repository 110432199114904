/* eslint react/jsx-key: off */
import React from "react";

import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  ReferenceField,
  ShowButton,
  EditButton,
  Pagination,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";

import Aside from "./Aside";

const AnnotationBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const AnnotationFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Organization ID" source="organizationID" defaultValue="" />,
  <TextInput label="Container ID" source="containerID" defaultValue="" />,
];

const AnnotationPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const AnnotationDetailPanel = ({ permissions, ...props }) => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <DateField source="created_at" showTime />
    </SimpleShowLayout>
  );
};

const AnnotationList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={AnnotationFilters}
    // filterDefaultValues={{ role: 'user' }}
    sort={{
      field: "id",
      order: "ASC",
    }}
    aside={<Aside />}
    bulkActionButtons={<AnnotationBulkActionButtons />}
    pagination={<AnnotationPagination />}
  >
    <Datagrid optimized expand={<AnnotationDetailPanel />}>
      <TextField source="id" />

      <TextField source="message_type" />
      <TextField source="message" />

      <EditButton label="Edit" />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default AnnotationList;
