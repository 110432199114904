// in src/posts.js
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  ReferenceInput,
  required,
  useEditContext,
  useTranslate,
  FormDataConsumer,
} from "react-admin";

import Aside from "./Aside";

const AnnotationTitle = () => {
  const { isLoading } = useEditContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return <span>{translate("annotation.create.title")}</span>;
};

const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

const AnnotationCreate = (props) => (
  <Create {...props} title={<AnnotationTitle />} aside={<Aside />}>
    <SimpleForm>
      <SelectInput
        source="message_type"
        choices={[
          { id: "U", name: "User Message" },
          { id: "S", name: "System Message" },
        ]}
        validate={required()}
      />

      <TextInput multiline source="message" validate={required()} />

      <ReferenceInput
        label="Organization"
        source="organization_id"
        reference="Organization"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
      >
        <SelectInput optionText={optionRenderer} />
      </ReferenceInput>

      {/* this is how to do a dependent select in react-admin */}
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Container"
            source="container_id"
            reference="Container"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
            filter={{ organizationId: formData.organization_id }}
          >
            <SelectInput optionText={optionRenderer} />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <DateInput source="start_date" validate={required()} />
      <DateInput source="end_date" validate={required()} />
    </SimpleForm>
  </Create>
);

export default AnnotationCreate;
