import React from "react";
import { useTranslate, useShowContext, useRecordContext } from "react-admin";

const PromptTemplateTitle = ({ edit }) => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;

  let titleKey;

  if (edit) {
    titleKey = "prompt_template.edit.title";
  } else {
    titleKey = "prompt_template.show.title";
  }

  return (
    <span>
      {record ? translate(titleKey, { id: record.id }) : ""}
    </span>
  );
};

export default PromptTemplateTitle;