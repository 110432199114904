import React from "react";
import {
  useTranslate,
  Create,
  SaveButton,
  Toolbar,
  SimpleForm,
  TextInput,
  SelectInput,
  required
} from "react-admin";

import JsonInput from "../AlembicJsonFields/JsonInput";
import { makeStyles } from "@mui/styles";
import Aside from "./Aside";
import ConfigDefaultScopeChoices from "../ConfigDefault/ConfigDefaultScopeChoices";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ConfigOverrideCreateToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

const ConfigOverrideCreate = ({ children, ...props }) => {
  const translate = useTranslate();

  return (
    <Create
      {...props}
      aside={<Aside />}
      title={translate("config_overrides.create.title")}
      toolbar={<ConfigOverrideCreateToolbar />}
    >
      <SimpleForm>
        <SelectInput source="scope" fullWidth validate={required()} choices={ConfigDefaultScopeChoices} />
        <TextInput source="config_key" fullWidth validate={required()} format={(v) => v ? v.toLowerCase() : ''} />
        <TextInput source="description" fullWidth validate={required()} />
        <JsonInput source="config_value" isRequired />
        <br />
        <br />
        <br />
        <br />
        <br />
      </SimpleForm>
    </Create>
  );
};

export default ConfigOverrideCreate;
