import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Organizations</Typography>
      <Typography variant="body2">
        Organizations are the primary, top-level container for customers.
      </Typography>
    </div>
  );
};

export default Aside;
