/* eslint react/jsx-key: off */
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";

import IdAndNameField from "../IdAndNameField";
import MoveButton from "../MoveButton";
import RenameContainerButton from "../RenameContainerButton";

import {
  Show,
  Tab,
  TabbedShowLayout,
  DateField,
  TextField,
  NumberField,
  BooleanField,
  TopToolbar,
  Button,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
} from "react-admin";

import { Link } from "react-router-dom";
import Aside from "./Aside";
import BecomeButton from "../User/BecomeButton";

import ContainerTitle from "./ContainerTitle";

const CreateNewECDConfigButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={{
        pathname: "/ECDConfig/create",
        state: {
          record: {
            container_id: record && parseInt(record.id, 10),
            container_name: record && record.name,
          },
        },
      }}
      label="ecdconfig.create.action"
    >
      <SettingsIcon />
    </Button>
  );
};

const EditECDButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: `/ECDConfig/${record.ecd_config_id}/edit`,
      state: {
        record: {
          container_id: record && parseInt(record.id, 10),
          container_name: record && record.name,
        },
      },
    }}
    label="ecdconfig.edit.action"
  >
    <SettingsIcon />
  </Button>
);

const ContainerShowActions = ({ data }) => (
  <TopToolbar>
    {data && data.ecd_config_id === undefined && (
      <CreateNewECDConfigButton record={data} />
    )}
    {data && data.ecd_config_id !== undefined && (
      <EditECDButton record={data} />
    )}
  </TopToolbar>
);

const ContainerShow = ({ ...props }) => {
  return (
    <Show
      {...props}
      title={<ContainerTitle show />}
      aside={<Aside />}
      actions={<ContainerShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="container.tab.summary">
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField
            label="Organization"
            source="organization_id"
            reference="Organization"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Parent Container"
            source="parent_id"
            reference="Container"
            link="show"
          >
            <IdAndNameField />
          </ReferenceField>
          <DateField source="last_poll_time" showTime />
          <DateField source="next_poll_time" showTime />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />

          <BooleanField source="paused" />

          <NumberField
            source="valuation_instagram"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="valuation_facebook"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="valuation_linkedin"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="valuation_youtube"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField source="ecd_config_id" />
          <TextField source="nasdaq_tickers" />
        </Tab>

        <Tab label="container.tab.children">
          <ReferenceManyField label="Containers" reference="Container" target="parentId">
            <Datagrid>
              <TextField source="id" />
              <ReferenceField
                label="Container"
                source="id"
                reference="Container"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>

              <ReferenceField
                label="Parent Container"
                source="parent_id"
                reference="Container"
                link="show"
              >
                <IdAndNameField />
              </ReferenceField>
              <TextField source="name" />
              <MoveButton label="Change Parent" />
              <RenameContainerButton label="Rename" />

            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="container.tab.users">
          <ReferenceManyField label="Users" reference="User" target="homeContainerId">
            <Datagrid>
              <TextField source="id" />
              <ReferenceField
                label="Organization"
                source="current_organization_id"
                reference="Organization"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="role.name" label="Org Role" />
              <TextField source="first_name" />
              <TextField source="last_name" />
              <TextField source="email" />
              <TextField source="phone" />
              <ShowButton label="Show" />
              <BecomeButton source="actions" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ContainerShow;
