import get from "lodash/get";
import React from "react";
import ReactJson from "react-json-view";
import { useRecordContext } from "react-admin";

const JsonField = (props) => {
  const { source, jsonString = false, reactJsonOptions = {} } = props;
  const record = useRecordContext();
  if (!record) return null;

  let src = get(record, source);

  if (jsonString) {
    src = JSON.parse(src);
  }

  return <ReactJson {...reactJsonOptions} src={src || {}} />;
};

export default JsonField;
