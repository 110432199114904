/* eslint react/jsx-key: off */
import React from "react";
import { useTranslate, useShowContext, useRecordContext } from "react-admin";

const AuditEntryTitle = ({ show }) => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;
  let titleKey;

  if (show) {
    titleKey = "audit_entry.show.title";
  } else {
    titleKey = "audit_entry.edit.title";
  }

  return (
    <span>
      {translate(titleKey, {
        id: record.id,
        created_at: record.created_at,
      })}
    </span>
  );
};

export default AuditEntryTitle;
