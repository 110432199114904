import { gql } from "@apollo/client";

export const ORG_LIST = gql`
  query allOrganizations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: OrganizationFilter
  ) {
    items: allOrganizations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      user_id
      org_uuid
      name
      company_short_name
      business_type_id
      structure_type_id
      avatar_handle
      created_at
      updated_at

      week_start
      year_start

      max_containers
      max_users

      mfa_mandatory
      account_manager_user_id
      customer_type
      account_type

      __typename
    }
    total: _allOrganizationsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;

export const ORG_ONE = gql`
  query Organization($id: ID!) {
    data: Organization(id: $id) {
      id
      user_id
      org_uuid
      name
      company_short_name
      business_type_id
      structure_type_id
      avatar_handle
      created_at
      updated_at

      week_start
      year_start

      max_containers
      max_users

      mfa_mandatory
      account_manager_user_id
      customer_type
      account_type

      __typename
    }
  }
`;

export const ORG_UPDATE = gql`
  mutation updateOrganization($data: OrganizationInput!) {
    updateOrganization(data: $data) {
      id
      user_id
      name
      company_short_name
      account_manager_user_id
      customer_type
      account_type
      max_containers
      max_users
      week_start
      year_start
      mfa_mandatory
    }
  }
`;
