import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React from "react";

import { Button } from "@mui/material";
import MoveDown from "@mui/icons-material/MoveDown";

import { Form, required, useNotify, useRefresh } from "react-admin";
import MuiTextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'
import { useMutation } from 'react-query';

import { flushSync } from 'react-dom';

import {
    ReferenceInput, SelectInput, useRecordContext, useDataProvider,
} from "react-admin";

const TextInput = withStyles({
    root: {
        margin: '16px 0px'
    }
})(MuiTextField);

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const MoveButton = (props) => {
    const [open, setOpen] = React.useState(false); // Controls modal
    const [version, setVersion] = React.useState(0);
    const [newParent, setNewParent] = React.useState(null);
    const [rootChange, setRootChange] = React.useState(false);

    const notify = useNotify(); // Initialize notify object to send notification to dashboard
    const refresh = useRefresh();

    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const { mutate, isLoading } = useMutation(
        ['Container', 'update', { id: record.id, data: { new_parent_id: newParent } }],
        () => dataProvider.update('Container', { id: record.id, data: { new_parent_id: rootChange ? 'root' : newParent } }),
        { onSuccess: (data) => { refresh(); setOpen(false); notify('container.response.moved'); } }
    );

    React.useEffect(() => {
        setNewParent(record.parent_id);
    }, [record]);

    const handleSubmit = async values => {
        mutate();
        setOpen(false);
        refresh();
        setRootChange(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Move Container: {record.name} </DialogTitle>
                <DialogContent>
                    <Form>
                        <ReferenceInput
                            key={version}
                            label="New Parent"
                            source="parent_id"
                            reference="Container"
                            sort={{ field: 'name', order: 'ASC' }}
                            perPage={1000}
                            filter={{ organizationId: record.organization_id }}>
                            <SelectInput
                                label="New Parent"
                                validate={required()}
                                optionText={(record => `${record.name} (${record.id})`)}
                                optionValue="id"
                                onChange={event => setNewParent(event.target.value)}
                            />
                        </ReferenceInput>
                    </Form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => {
                        console.log('new parent: ' + newParent)
                        flushSync(() => {
                            setRootChange(true);

                        });
                        handleSubmit(false)
                    }}>Move to Root</Button>
                    <Button onClick={() => handleSubmit(false)}>Move to Selection</Button>
                </DialogActions>
            </Dialog>
            {
                record.parent_id && (
                    <Button variant="text" color="primary" aria-label="create" onClick={() => setOpen(true)}>
                        {/* this might not be the best approach but we do not want people to be able to move the top container */}
                        <MoveDown style={{ marginRight: '4px' }} /> Move
                    </Button>
                )
            }

            {
                !record.parent_id && (
                    <>Top-Level</>
                )
            }
        </>

    );
};

export default MoveButton;  
