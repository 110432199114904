import React from "react";
import { Show, Tab, TabbedShowLayout, TextField, TextInput } from "react-admin";
import Aside from "./Aside";

/**
 * A react component for creating a classification event.
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The ClassificationEventCreate component.
 */
const ClassificationEventShow = ({ ...props }) => {
  return (
    <Show {...props} aside={<Aside />}>
      <TabbedShowLayout>
        <Tab label="classification.tab.summary">
          <TextField source="id" label="ID" />
          <TextField source="linked_account_id" label="Linktoken Id" />
          <TextField source="goal_id" label="Goal Id (GA3)" />
          <TextField source="event_name" label="Event Name (GA4/AA)" />
          <TextField source="event_value" label="Event value (AA)" />
          <TextField source="classification" label="Classification" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ClassificationEventShow;
