/* eslint react/jsx-key: off */
import React from "react";

import {
  Show,
  Tab,
  TabbedShowLayout,
  DateField,
  TextField,
  TopToolbar,
  useTranslate,
  useShowContext,
  useRecordContext,
  ReferenceField,
} from "react-admin";

import Aside from "./Aside";
import FullNameField from "../FullNameField";

const AnnotationShowActions = ({ data }) => (
  <TopToolbar>
    {data && data.ecd_config_id === undefined && (
      <CreateNewECDConfigButton record={data} />
    )}
    {data && data.ecd_config_id !== undefined && (
      <EditECDButton record={data} />
    )}
  </TopToolbar>
);

const AnnotationTitle = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return (
    <span>
      {record
        ? translate("annotation.show.title", {
          id: record.id,
          message: record.message,
        })
        : ""}
    </span>
  );
};

const AnnotationShow = ({ ...props }) => {
  return (
    <Show
      {...props}
      title={<AnnotationTitle />}
      aside={<Aside />}
      actions={<AnnotationShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="annotation.tab.summary">
          <TextField source="id" />
          <ReferenceField
            label="Created By"
            source="user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>
          <TextField source="message" />
          <ReferenceField
            label="Organization"
            source="organization_id"
            reference="Organization"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Container"
            source="container_id"
            reference="Container"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="start_date" />
          <DateField source="end_date" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <DateField source="deleted_at" showTime />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default AnnotationShow;
