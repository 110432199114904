import React from "react";
import { Grid } from "@mui/material";
import Logo from "./ALE_Logo_navy_small.png";

const AlembicLogo = () => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <img src={Logo} alt="Alembic" width="300px" />
    </Grid>
  );
};

export default AlembicLogo;
