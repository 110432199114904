/* eslint react/jsx-key: off */
import React from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  ReferenceInput,
  PasswordInput,
  FormDataConsumer,
  required,
  AutocompleteInput,
} from "react-admin";

import Aside from "./Aside";

const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

// this is a very basic form. Later it should actually reference the Securityroles object
const UserCreate = ({ permissions, ...props }) => (
  <Create {...props} aside={<Aside />}>
    <SimpleForm>
      <ReferenceInput
        label="Organization"
        source="organization_id"
        reference="Organization"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>

      {/* this is how to do a dependent select in react-admin */}
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Container"
            source="home_container_id"
            reference="Container"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
            filter={{ organizationId: formData.organization_id }}
          >
            <SelectInput optionText={optionRenderer} />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <TextInput
        source="email"
        validate={required()}
        inputProps={{ autoComplete: "off" }}
      />

      <PasswordInput source="password" inputProps={{ autoComplete: "off" }} />

      <SelectInput
        source="role_id"
        validate={required()}
        defaultValue={0}
        choices={[
          {
            id: 0,
            name: "Super Admin",
          },
          {
            id: 1,
            name: "User Management Admin",
          },
          {
            id: 2,
            name: "Billing Administrator",
          },
          {
            id: 3,
            name: "Campaign Administrator",
          },
          {
            id: 8,
            name: "Account Administrator",
          },
          {
            id: 28,
            name: "Link Only",
          },
          {
            id: 32,
            name: "View Only",
          },
        ]}
      />

      <TextInput
        source="first_name"
        initialValue="Slim"
        autoFocus
        validate={required()}
      />
      <TextInput
        source="last_name"
        initialValue="Shady"
        validate={required()}
      />

      <TextInput source="phone" validate={required()} />

      <SelectInput
        source="time_zone"
        choices={Intl.supportedValuesOf("timeZone").map((tz) => ({
          id: tz,
          name: tz,
        }))}
        defaultValue={Intl.DateTimeFormat().resolvedOptions().timeZone}
        validate={required()}
      />

      <TextInput source="title" initialValue="CEO" />
    </SimpleForm>
  </Create>
);

export default UserCreate;
