import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Aside = () => (
  <Paper
    sx={{ width: 200, margin: "1em", display: { sm: "none", xs: "block" } }}
  >
    <Typography variant="h6">Annotations</Typography>

    <Typography variant="body2">
      Messages that appear on the masthead of the system or on the dashboard.
    </Typography>
    <p />

    <Typography variant="body2">
      Messages with type "S" are system messages, displayed at the top of the
      page for applicable users. For example,{" "}
      <em>"System is down for maintenance" or "System is back up".</em>
    </Typography>
    <p />

    <Typography variant="body2">
      Messages with type "U" are user-generated annotations, attached to
      dashboards and dashboards events. For Example,{" "}
      <em>"Campaign X running on these days."</em>
    </Typography>
    <p />
  </Paper>
);

export default Aside;
