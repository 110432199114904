import { gql } from "@apollo/client";

export const NOTIFICATION_LIST = gql`
  query allNotifications(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: NotificationFilter
  ) {
    items: allNotifications(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      from_user_id
      to_user_id
      container_id
      organization_id
      scope
      subject
      body
      cta_object_id
      cta_object_type
      url
      created_at
      updated_at 
      deleted_at
      __typename
    }
    total: _allNotificationsMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const NOTIFICATION_ONE = gql`
  query Notification($id: ID!) {
    data: Notification(id: $id) {
      id
      from_user_id
      to_user_id
      container_id
      organization_id
      scope
      subject
      body
      cta_object_id
      url
      cta_object_type
      created_at
      updated_at 
      deleted_at
      __typename
    }
  }
`;

export const NOTIFICATION_CREATE = gql`
  mutation createAdminNotification($data: NotificationInput!) {
    createAdminNotification(data: $data) {
      id
      to_user_id
      container_id
      organization_id
      scope
      subject
      body
      cta_object_id
      url
      cta_object_type
      created_at
      updated_at 
      deleted_at
      __typename
    }
  }
`;

export const NOTIFICATION_UPDATE = gql`
  mutation updateAdminNotification($data: NotificationUpdateInput!) {
    updateAdminNotification(data: $data) {
      id
      to_user_id
      container_id
      organization_id
      scope
      subject
      body
      cta_object_id
      url
      cta_object_type
      created_at
      updated_at 
      deleted_at
      __typename
    }
  }
`;

export const NOTIFICATION_DELETE = gql`
  mutation deleteAdminNotification($id: ID!) {
    deleteAdminNotification(id: $id) {
      message
      result
    }
  }
`;
