/* eslint react/jsx-key: off */
import React from "react";
import FullNameField from "../FullNameField";

import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  SelectInput,
  ReferenceField,
  ShowButton,
  EditButton,
  Pagination,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin";

import Aside from "./Aside";

const NotificationBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const NotificationFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Organization ID" source="organizationID" defaultValue="" />,
  <TextInput label="Container ID" source="containerID" defaultValue="" />,
  <SelectInput source="scope" choices={[
    { id: 'ALL', name: 'All Users' },
    { id: 'CONTAINER', name: 'Container Message' },
    { id: 'ORGANIZATION', name: 'Organization Message' },
    { id: 'USER', name: 'User Message' },
  ]} />
];

const NotificationPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const NotificationDetailPanel = ({ permissions, ...props }) => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <ReferenceField
        label="From User"
        source="from_user_id"
        reference="User"
        link="show"
      >
        <FullNameField />
      </ReferenceField>

      <ReferenceField
        label="To User"
        source="to_user_id"
        reference="User"
        link="show"
      >
        <FullNameField />
      </ReferenceField>

      <TextField source="body" />

      <TextField source="cta_object_id" />
      <TextField source="cta_object_type" />

      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <DateField source="deleted_at" showTime />
    </SimpleShowLayout>
  );
};

const NotificationList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={NotificationFilters}
    sort={{
      field: "created_at",
      order: "DESC",
    }}
    aside={<Aside />}
    bulkActionButtons={<NotificationBulkActionButtons />}
    pagination={<NotificationPagination />}
  >
    <Datagrid optimized expand={<NotificationDetailPanel />}>
      <TextField source="id" />
      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField source="created_at" showTime />

      <TextField source="subject" />
      <TextField source="scope" />

      <EditButton label="Edit" />
      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default NotificationList;
