/* eslint react/jsx-key: off */
import React from "react";
import {
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField,
  DateField,
  ShowButton,
  ReferenceField,
  Pagination,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
  SimpleShowLayout,
} from "react-admin";

import { Chip } from "@mui/material";
import FullNameField from "../FullNameField";
import JsonField from "../AlembicJsonFields/JsonField";

import Aside from "./Aside";

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const AuditEntryFilter = ({ permissions, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <SearchInput source="container_id" placeholder="Container ID" />
    <ReferenceArrayInput source="organization_id" reference="Organization" >
      <AutocompleteArrayInput
        source="ids"
        optionText='name'
        optionValue='id'
      />
    </ReferenceArrayInput>

    <QuickFilter
      source="activeOrgOnly"
      label="Only Active Organizations"
      defaultValue={true}
    />

  </Filter>
);

const AuditEntryPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const AuditEntryPanel = () => (
  <SimpleShowLayout>
    <JsonField source="meta" label="Event Metadata" fullWidth />
  </SimpleShowLayout>
);

const AuditEntryList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<AuditEntryFilter permissions={permissions} />}
    sort={{ field: "created_at", order: "DESC" }}
    aside={<Aside />}
    pagination={<AuditEntryPagination />}
    title="audit_entry.list.title"
  >
    <Datagrid optimized expand={<AuditEntryPanel />}>
      <TextField source="id" />

      <DateField source="created_at" showTime />

      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="User"
        source="user_id"
        reference="User"
        link="show"
      >
        <FullNameField />
      </ReferenceField>

      <TextField source="model" label="Object Type" />
      <TextField source="model_id" label="Object ID" />
      <TextField source="action" />

      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default AuditEntryList;
