/* eslint react/jsx-key: off */
import React from "react";
import { useTranslate, useShowContext, useRecordContext } from "react-admin";

const ContainerTitle = ({ show }) => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;
  let titleKey;

  if (show) {
    titleKey = "container.show.title";
  } else {
    titleKey = "container.edit.title";
  }
  return (
    <span>
      {record
        ? translate(titleKey, {
          id: record.id,
          name: record.name,
        })
        : ""}
    </span>
  );
};

export default ContainerTitle;
