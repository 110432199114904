/* eslint react/jsx-key: off */
import React from "react";
import { useTranslate, useShowContext, useRecordContext } from "react-admin";

const LinktokenTitle = ({ show }) => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;
  let titleKey;

  if (show) {
    titleKey = "linktoken.show.title";
  } else {
    titleKey = "linktoken.edit.title";
  }
  return (
    <span>
      {record
        ? translate(titleKey, {
            id: record.id,
            remote_name: record.remote_name,
          })
        : ""}
    </span>
  );
};

export default LinktokenTitle;
