import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Causal Chains Summary Review</Typography>
      <Typography variant="body2">
        This page shows a causal chains summary for review. Choose either
        "Approved" or "Rejected" from the drop down menu, then click save. Note:
        There is no way to undo, so be sure of your choice!
      </Typography>
    </div>
  );
};

export default Aside;
