// in src/posts.js
import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  useEditContext,
  useTranslate,
  FormDataConsumer,
  AutocompleteInput
} from "react-admin";

import Aside from "./Aside";

const NotificationTitle = () => {
  const { isLoading } = useEditContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return <span>{translate("notification.create.title")}</span>;
};

const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

const nameRenderer = (choice) => `${choice.first_name} ${choice.last_name} (${choice.id})`;

const NotificationCreate = (props) => (
  <Create {...props} title={<NotificationTitle />} aside={<Aside />}>
    <SimpleForm>
      <h2> Target </h2>
      <p>
        For system-wide messages, leave these blank.
      </p>
      <p>
        Set scope to ALL for all users or restrict to a specific org/container above.
      </p>

      <SelectInput source="scope" validate={required()} choices={[
        { id: 'ALL', name: 'All Users' },
        { id: 'CONTAINER', name: 'Container Message' },
        { id: 'ORGANIZATION', name: 'Organization Message' },
        { id: 'USER', name: 'User Message' },
      ]} />

      <ReferenceInput
        label="To User ID (from will be set to your ID)"
        source="to_user_id"
        reference="User"
        sort={{ field: "last_name", order: "ASC" }}
        perPage={1000}
      >
        <AutocompleteInput optionText={nameRenderer} />
      </ReferenceInput>

      <ReferenceInput
        label="Target Organization"
        source="organization_id"
        reference="Organization"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>

      {/* this is how to do a dependent select in react-admin */}
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <ReferenceInput
            label="Target Container"
            source="container_id"
            reference="Container"
            sort={{ field: "name", order: "ASC" }}
            perPage={1000}
            filter={{ organizationId: formData.organization_id }}
          >
            <AutocompleteInput optionText={optionRenderer} />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <h2> Message </h2>
      <TextInput source="subject" validate={required()} />
      <TextInput multiline source="body" validate={required()} />

      <p>
        Determines where clicking on the notification takes the user (optional)
      </p>

      <SelectInput source="cta_object_type" choices={[
        { id: 'assets', name: 'Asset' },
        { id: 'container', name: 'Container' },
        { id: 'campaign_events', name: 'Campaign Event' },
        { id: 'linktoken', name: 'Linktoken' },
        { id: 'themes', name: 'Theme' },
        { id: 'topics', name: 'Topic' },
        { id: 'users', name: 'User' },
        { id: 'url', name: 'URL' }
      ]} />

      <p>
        If the above is set, we'll take the user to this object id based on the above type (ignored in current UI)
      </p>
      <TextInput source="cta_object_id" label="CTA Object ID" />

      <p>
        If URL is specified, please enter a valid URL to link to.
      </p>
      <TextInput source="url" label="URL" />
    </SimpleForm>
  </Create>
);

export default NotificationCreate;
