import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Config Fallbacks</Typography>
      <Typography variant="body1">
        These settings serve as default configurations for various parts of our system.
        Any changes you make here will apply to all items (such as Linktokens, Organizations, and Containers)
        unless they have their own specific settings elsewhere.
      </Typography>
      <br />
      <Typography variant="body1">
        If you want to set up different rules for a particular scope and scope ID,
        click the 'Override' button on the right side of the page.
      </Typography>
      <br />
      <Typography variant="body1">
        Overrides can only be setup after the default settings have been saved.

      </Typography>

    </div >
  );
};

export default Aside;
