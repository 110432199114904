/* eslint react/jsx-key: off */
import React from "react";
import {
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  BooleanField,
  ReferenceField,
  Datagrid,
  DateField,
  ReferenceManyField,
  ShowButton,
  Pagination,
} from "react-admin";
import FullNameField from "../FullNameField";
import IdAndNameField from "../IdAndNameField";

import UserTitle from "./UserTitle";
import Aside from "./Aside";

const UserShow = ({ permissions, ...props }) => {
  return (
    <Show title={<UserTitle />} aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <Tab label="user.form.summary">
          <TextField source="id" />
          <TextField source="account_state" />
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <DateField source="last_access_time" showTime />
          <FullNameField />
          <ReferenceField
            label="Container"
            source="home_container_id"
            reference="Container"
          >
            <IdAndNameField />
          </ReferenceField>
          <ReferenceField
            label="Organization"
            source="current_organization_id"
            reference="Organization"
          >
            <IdAndNameField />
          </ReferenceField>
          <TextField source="role.name" label="Org Role" />
          <TextField source="email" />
          <TextField source="avatar_url" />
          <TextField source="phone" />
          <TextField source="company" />
          <TextField source="title" />
          <TextField source="bio" />
          <TextField source="time_zone" />
          <BooleanField source="setup_complete" />
          <BooleanField source="force_password_change" />
          <BooleanField source="otp_enabled" />
          <BooleanField source="email_verified" />
          <DateField source="eula_accepted_at" showTime />
        </Tab>
        <Tab label="org.tab.linktokens">
          <ReferenceManyField
            reference="Linktoken"
            target="user_id"
            label=""
            pagination={<Pagination />}
            rowsPerPageOptions={[10, 25, 50, 100]}
            perPage={10}
          >
            <Datagrid>
              <TextField source="id" />
              <DateField source="created_at" showTime />

              <DateField source="last_poll_time" showTime />

              <ReferenceField
                label="Container"
                source="container_id"
                reference="Container"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="User"
                source="user_id"
                reference="User"
                link="show"
              >
                <FullNameField />
              </ReferenceField>
              <TextField source="type" />
              <TextField source="remote_name" />
              <TextField source="remote_id" />

              <BooleanField source="deleted" />
              <BooleanField source="enabled" />
              <BooleanField source="valid_token" />

              <ShowButton label="Show" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="user.form.audits">
          <ReferenceManyField
            reference="AuditEntry"
            target="user_id"
            label="Audit Entries"
            pagination={<Pagination />}
            rowsPerPageOptions={[10, 25, 50, 100]}
            perPage={10}
          >
            <Datagrid>
              <TextField source="id" />
              <DateField source="created_at" showTime />
              <ReferenceField
                label="Organization"
                source="organization_id"
                reference="Organization"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Container"
                source="container_id"
                reference="Container"
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="User"
                source="user_id"
                reference="User"
                link="show"
              >
                <FullNameField />
              </ReferenceField>
              <TextField source="model" label="Object Type" />
              <TextField source="model_id" label="Object ID" />
              <TextField source="action" />

              <ShowButton label="Show" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {permissions === "admin" && (
          <Tab label="user.form.security" path="security">
            <TextField source="role" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
