import { gql } from "@apollo/client";

export const INITIAL_USER_AUTH = `
  id
  avatar_url
  first_name
  last_name
  email
  phone
  company
  title
  bio
  time_zone
  setup_complete
  force_password_change
  otp_enabled
  admin_level
  current_organization_id
  current_organization {
    id
    name
  }
  home_container_id
  home_container {
    id
    name
    avatar_url
  }
  privileges {
    short_name
  }
  preferences {
    key
    value_i
    value_s
  }
`;

export const SIGN_IN = gql`
  mutation ($user: String!, $password: String!) {
    signIn(emailOrPhone: $user, password: $password) {
      expires
      user {
        ${INITIAL_USER_AUTH}
      }
      token 
     }
  }
`;

export const BECOME_USER_LOGIN = gql`
  mutation ($token: String!) {
    becomeUserLogin(token: $token) {
      user {
        ${INITIAL_USER_AUTH}
      }
      expires
      token
    }
  }
`;
