/* eslint react/jsx-key: off */
import React, { useEffect, useState } from "react";
import {
  useTranslate,
  useShowContext,
  useRecordContext,
  useDataProvider,
  useNotify,
} from "react-admin";
import PropTypes from "prop-types";

const ECDConfigTitle = ({ edit }) => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();
  const notify = useNotify();

  const dataProvider = useDataProvider();

  const [container, setContainer] = useState();
  const [organization, setOrganization] = useState();

  const [error, setError] = useState();
  const [containerLoading, setContainerLoading] = useState(true);
  const [organizationLoading, setOrganizationLoading] = useState(true);

  useEffect(() => {
    if (record) {
      dataProvider
        .getOne("Container", { id: record.container_id })
        .then(({ data }) => {
          setContainer(data);
          setContainerLoading(false);
        })
        .catch((error) => {
          setError(error);
          setContainerLoading(false);
        });
    }
  }, [record]);

  useEffect(() => {
    if (container) {
      dataProvider
        .getOne("Organization", { id: container.organization_id })
        .then(({ data }) => {
          setOrganization(data);
          setOrganizationLoading(false);
        })
        .catch((error) => {
          setError(error);
          setOrganizationLoading(false);
        });
    }
  }, [container]);

  if (isLoading || containerLoading || organizationLoading) return null;

  if (error) {
    notify(error.message, "error");
    return <span>Failed to get title</span>;
  }

  if (edit) {
    return (
      <span>
        {translate("ecdconfig.edit.title", {
          id: record.id,
          organization_name: organization.name,
          container_id: container.id,
          container_name: container.name,
        })}
      </span>
    );
  }

  return (
    <span>
      {record
        ? translate("ecdconfig.show.title", {
          id: record.id,
          organization_name: organization.name,
          container_id: container.id,
          container_name: container.name,
        })
        : ""}
    </span>
  );
};

ECDConfigTitle.propTypes = {
  record: PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
  edit: PropTypes.bool,
};

ECDConfigTitle.defaultProps = {
  record: { name: "" },
  edit: false,
};

export default ECDConfigTitle;
