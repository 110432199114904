import React from "react";
import {
  Create,
  SaveButton,
  SimpleForm,
  Toolbar,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
  useEditContext,
  useTranslate,
} from "react-admin";

import { makeStyles } from "@mui/styles";

// import Aside from "./Aside";
import JsonInput from "../AlembicJsonFields/JsonInput";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const PromptTemplateTitle = () => {
  const { isLoading } = useEditContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return <span>{translate("prompt_template.create.title")}</span>;
};

/**
 * Custom Toolbar for the Create form
 *
 * Save button only (always enabled to prevent disabled button during clone)
 */
const PromptTemplateCreateToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton alwaysEnable />
    </Toolbar>
  );
};

const optionRenderer = (choice) => `${choice.name} (${choice.id})`;

const defaultGroupsPlaceholder = {
  media_mix: [
    {
      model: "databricks/dbrx-instruct",
      parameters: { temperature: 0.1, max_new_tokens: 20000 },
      system_prompt:
        "We will begin by waiting for my input text for you to use. Skip the pleasantries. I only want to read something that increases my understanding.\n\nIf your answer includes a code snippet or a writing sample, put that first with any explanation after.\n\nIf I provide additional information and ask for a revised code snippet or writing sample, don't explain - just revise the answer. For example, don't explain why a code snippet is different. Just write the new code.\n\nWhen you help me write text for me to share with others, be succinct, clear, professional, and matter-of-fact, with no buzzwords or exaggeration.\n\nRemove unnecessary words. Use definite declarative statements like “does,” “is” or “will” instead of hedging with “maybe,” “possibly” or “could.”\n\nOnly use the active voice-not the passive voice. Use a style that sounds and feels natural to read aloud. Assume the reader only completed grade 10.\n\nDon't be bombastic. Instead of \"In the dynamic world of sales\" say \"In sales.\" Don't sound like AI.\n\nFor any web url's or digital marketing metrics try to translate the pseudo urls into an english language statement. 10th graders can not read raw code. Please try to show what individual campaign actions and content like translating the events to regular english.",
      template_prompt:
        "Read the following report:\n\n{{input}}\n\nThe report above is a set of marketing analytics events. Translate it for non-technical team members.\n\nExample Input:\n\nAA_CONVERSIONS_BY_EVENT\n- \"gtc-ov-pricing-download-form:nv:omniverse:download\"\n- sdk-cuda-form-for-devzone:nvundefined\n\nAA_INTERACTIONS_BY_EVENT\n- 391.35-desktop-win10-64bit-international-whql.exe:nv:content:driverdownloads:confirmation.php\n- 4e9abe7b-fdc7-4cd2-8487-dc3a99f30e98.pdf:nv:investor:nvidia:financial-info:financial-reports:default\n\nExample Output:\n\nConversions by Event\n- GTC-OV Pricing Download Form: A user downloaded the pricing form for GTC-OV, which is related to Nvidia's Omniverse platform.\n- SDK-CUDA Form for DevZone: A user submitted a form or downloaded the CUDA Software Development Kit from Nvidia's Developer Zone. The event tag appears incomplete or undefined.\n\nInteractions by Event\n- 391.35-desktop-win10-64bit-international-whql.exe: A user downloaded the 391.35 version of the Nvidia driver for Windows 10 64-bit, which is WHQL certified.\n- 4e9abe7b-fdc7-4cd2-8487-dc3a99f30e98.pdf: A user accessed a PDF document related to Nvidia's financial reports, possibly an investor-related document.\n\nOutput:\n\n",
    },
    {
      model: "meta-llama/Meta-Llama-3-70B",
      parameters: { temperature: 0.5, max_new_tokens: 3000 },
      system_prompt: "Continue the analysis based on previous insights.",
      template_prompt:
        "Imagine you are a marketing analyst at a company evaluating digital and non-digital campaign logs. Read the report below.\n\n{{input}}\n\n- Extract key insights with recommendation into a single bullet point for a high level executive to justify budget.\n- Use bullet points only.\n- Return one bulleted list only.\n\nReport:\n\n",
    },
  ],
  detected_events: [
    {
      model: "databricks/dbrx-instruct",
      parameters: { temperature: 0.1, max_new_tokens: 20000 },
      system_prompt:
        "We will begin by waiting for my input text for you to use. Skip the pleasantries. I only want to read something that increases my understanding.\n\nIf your answer includes a code snippet or a writing sample, put that first with any explanation after.\n\nIf I provide additional information and ask for a revised code snippet or writing sample, don't explain - just revise the answer. For example, don't explain why a code snippet is different. Just write the new code.\n\nWhen you help me write text for me to share with others, be succinct, clear, professional, and matter-of-fact, with no buzzwords or exaggeration.\n\nRemove unnecessary words. Use definite declarative statements like “does,” “is” or “will” instead of hedging with “maybe,” “possibly” or “could.”\n\nOnly use the active voice-not the passive voice. Use a style that sounds and feels natural to read aloud. Assume the reader only completed grade 10.\n\nDon't be bombastic. Instead of \"In the dynamic world of sales\" say \"In sales.\" Don't sound like AI.\n\nFor any web url's or digital marketing metrics try to translate the pseudo urls into an english language statement. 10th graders can not read raw code. Please try to show what individual campaign actions and content like translating the events to regular english.",
      template_prompt:
        "Read the following report:\n\n{{input}}\n\nThe report above is a set of marketing analytics events. Translate it for non-technical team members.\n\nExample Input:\n\nAA_CONVERSIONS_BY_EVENT\n- \"gtc-ov-pricing-download-form:nv:omniverse:download\"\n- sdk-cuda-form-for-devzone:nvundefined\n\nAA_INTERACTIONS_BY_EVENT\n- 391.35-desktop-win10-64bit-international-whql.exe:nv:content:driverdownloads:confirmation.php\n- 4e9abe7b-fdc7-4cd2-8487-dc3a99f30e98.pdf:nv:investor:nvidia:financial-info:financial-reports:default\n\nExample Output:\n\nConversions by Event\n- GTC-OV Pricing Download Form: A user downloaded the pricing form for GTC-OV, which is related to Nvidia's Omniverse platform.\n- SDK-CUDA Form for DevZone: A user submitted a form or downloaded the CUDA Software Development Kit from Nvidia's Developer Zone. The event tag appears incomplete or undefined.\n\nInteractions by Event\n- 391.35-desktop-win10-64bit-international-whql.exe: A user downloaded the 391.35 version of the Nvidia driver for Windows 10 64-bit, which is WHQL certified.\n- 4e9abe7b-fdc7-4cd2-8487-dc3a99f30e98.pdf: A user accessed a PDF document related to Nvidia's financial reports, possibly an investor-related document.\n\nOutput:\n\n",
    },
    {
      model: "meta-llama/Meta-Llama-3-70B",
      parameters: { temperature: 0.5, max_new_tokens: 3000 },
      system_prompt: "Continue the analysis based on previous insights.",
      template_prompt:
        "Imagine you are a marketing analyst at a company evaluating digital and non-digital campaign logs. Read the report below.\n\n{{input}}\n\n- Extract key insights with recommendation into a single bullet point for a high level executive to justify budget.\n- Use bullet points only.\n- Return one bulleted list only.\n\nReport:\n",
    },
  ],
};

const transform = (values) => {
  const { container_id, stages, is_default } = values;

  // filter expected values for creation (prevent other values passing in during clone)
  return { container_id: container_id || null, stages, is_default };
};

const PromptTemplateCreate = (props) => (
  <Create
    {...props}
    title={<PromptTemplateTitle />}
    transform={transform}
    // aside={<Aside />}
  >
    <SimpleForm toolbar={<PromptTemplateCreateToolbar />}>
      <ReferenceInput
        label="Container"
        source="container_id"
        reference="Container"
        sort={{ field: "name", order: "ASC" }}
        perPage={1000}
      >
        <AutocompleteInput
          sx={{ width: "400px" }}
          optionText={optionRenderer}
        />
      </ReferenceInput>

      <BooleanInput source="is_default" label="Default" defaultValue={false} />

      <JsonInput
        source="stages"
        label="Groups JSON"
        defaultValue={defaultGroupsPlaceholder}
      />
    </SimpleForm>
  </Create>
);

export default PromptTemplateCreate;
