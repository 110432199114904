import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const Aside = () => (
  <Paper
    sx={{ width: 200, margin: "1em", display: { sm: "none", xs: "block" } }}
  >
    <Typography variant="h6">Notifications</Typography>

    <Typography variant="body2">
      Notifications appear in the bell Icon in the top right corner of the
      user's screen. They can be used to inform the user of a new message or
      other event.
    </Typography>
    <p />
  </Paper>
);

export default Aside;
