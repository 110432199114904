/* eslint react/jsx-key: off */
import React from "react";
import { useTranslate, useShowContext, useRecordContext } from "react-admin";

import PropTypes from "prop-types";

const UserTitle = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return (
    <span>
      {record
        ? translate("user.edit.title", {
            title: `${record.first_name} ${record.last_name}`,
          })
        : ""}
    </span>
  );
};

UserTitle.propTypes = {
  record: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};

UserTitle.defaultProps = {
  record: { first_name: "Unknown", last_name: "User" },
};

export default UserTitle;
