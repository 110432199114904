import { gql } from "@apollo/client";

export const AUDIT_ENTRY_LIST = gql`
  query allAuditEntries(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AuditEntryFilter
  ) {
    items: allAuditEntries(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      user_id
      model
      model_id
      action
      meta
      created_at
      updated_at
      container_id
      organization_id
      __typename
    }
    total: _allAuditEntriesMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const AUDIT_ENTRY_ONE = gql`
  query AuditEntry($id: ID!) {
    data: AuditEntry(id: $id) {
      id
      user_id
      model
      model_id
      action
      meta
      created_at
      updated_at
      container_id
      organization_id
      __typename
    }
  }
`;
