// in src/Dashboard.js
import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Title } from "react-admin";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  h1: {
    fontSize: 24,
  },
  pos: {
    marginBottom: 12,
  },
});
export default () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Title title="Alembic Adminstration" />
      <CardContent>
        <Typography className={classes.h1} color="textPrimary" gutterBottom>
          Welcome to Alembic Administration
        </Typography>
        <hr />

        <Typography variant="subtitle1">
          Please select a table on the left to continue.
        </Typography>

        <Typography variant="body1">
          <small>
            <br />
            Unauthorized Access to this System is Prohibited.
            <br />
            All activities performed on this system are logged and monitored.
          </small>
        </Typography>
      </CardContent>
    </Card>
  );
};
