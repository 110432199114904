/* eslint react/jsx-key: off */
import React from "react";
import { useTranslate } from "react-admin";
import { useShowContext, useRecordContext } from "react-admin";

const OrgTitle = (props) => {
  const { show } = props;
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;
  let titleKey;

  if (show) {
    titleKey = "org.show.title";
  } else {
    titleKey = "org.edit.title";
  }

  return (
    <span>
      {record
        ? translate(titleKey, {
            id: record.id,
            name: record.name,
            company_short_name: record.company_short_name,
          })
        : ""}
    </span>
  );
};

export default OrgTitle;
