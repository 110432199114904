import React from "react";
import Typography from "@mui/material/Typography";

const Aside = () => {
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Audit Entries</Typography>
      <Typography variant="body2">
        Discrete events that have occurred in the system.
      </Typography>
    </div>
  );
};

export default Aside;
