import { gql } from "@apollo/client";

export const CONTAINER_LIST = gql`
  query allContainers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ContainerFilter
  ) {
    items: allContainers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      organization_id
      parent_id
      paused
      avatar_handle
      ecd_config_id
      valuation_instagram
      valuation_facebook
      valuation_linkedin
      valuation_youtube
      nasdaq_tickers
      created_at
      updated_at
      last_poll_time
      next_poll_time
      __typename
    }
    total: _allContainersMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;

export const CONTAINER_ONE = gql`
  query Container($id: ID!) {
    data: container(id: $id) {
      id
      name
      organization_id
      parent_id
      paused
      valuation_instagram
      valuation_facebook
      valuation_linkedin
      valuation_youtube
      ecd_config_id
      nasdaq_tickers
      last_poll_time
      next_poll_time
      created_at
      updated_at
      __typename
    }
  }
`;

export const MOVE_CONTAINER = gql`
  mutation moveContainer($id: ID!, $parent_id: ID!) {
    moveContainer(id: $id, parent_id: $parent_id) {
      id
    }
  }`;

export const RENAME_CONTAINER = gql`
  mutation updateContainer($id: ID!, $name: String!) {
    updateContainer(id: $id, name: $name) {
      id
      name
    }
  }`;

export const UPDATE_NASDAQ_TICKERS = gql`
  mutation updateContainer($id: ID!, $nasdaq_tickers: [String]!) {
  updateContainer(id: $id, nasdaqTickers: $nasdaq_tickers) {
    id
    nasdaq_tickers
  }
  }
`
