import React from "react";
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  ReferenceField,
  ShowButton,
  EditButton,
  Pagination,
  TextInput,
} from "react-admin";

/**
 * A react component for returning pagination in the table's footer.
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The ClassificationEventList component.
 */
const ClassificationEventPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

/**
 * A react component for listing all classification events as one to many reference
 * @param {Object} props - Props object.
 * @returns {JSX.Element} The ClassificationEventList component.
 */
const ClassificationEventList = ({ ...props }) => (
  <ReferenceManyField
    reference="ClassificationEvent"
    target="linked_account_id"
    {...props}
    sort={{
      field: "linked_account_id",
      order: "DESC",
    }}
    pagination={<ClassificationEventPagination />}
  >
    <Datagrid>
      <TextField source="id" />
      <ReferenceField
        label="Linked token id"
        source="linked_account_id"
        reference="Linktoken"
        link="show"
      >
        <TextField source="id" />
      </ReferenceField>
      <TextField source="goal_id" />
      <TextField source="event_name" />
      <TextField source="event_value" />
      <TextField source="classification" />

      <EditButton label="Edit" />
      <ShowButton label="Show" />
    </Datagrid>
  </ReferenceManyField>
);

export default ClassificationEventList;
