/* eslint react/jsx-key: off */
import React from "react";

import {
  Show,
  Tab,
  TabbedShowLayout,
  DateField,
  TextField,
  TopToolbar,
  useTranslate,
  useShowContext,
  useRecordContext,
  ReferenceField,
  UrlField
} from "react-admin";

import Aside from "./Aside";
import FullNameField from "../FullNameField";

const NotificationShowActions = ({ data }) => (
  <TopToolbar>
    {data && data.ecd_config_id === undefined && (
      <CreateNewECDConfigButton record={data} />
    )}
    {data && data.ecd_config_id !== undefined && (
      <EditECDButton record={data} />
    )}
  </TopToolbar>
);

const NotificationTitle = () => {
  const record = useRecordContext();
  const { isLoading } = useShowContext();
  const translate = useTranslate();

  if (isLoading) return null;

  return (
    <span>
      {record
        ? translate("notification.show.title", {
          id: record.id,
          subject: record.subject,
        })
        : ""}
    </span>
  );
};

const NotificationShow = ({ ...props }) => {
  return (
    <Show
      {...props}
      title={<NotificationTitle />}
      aside={<Aside />}
      actions={<NotificationShowActions />}
    >
      <TabbedShowLayout>
        <Tab label="notification.tab.summary">
          <TextField source="id" />
          <ReferenceField
            label="From User"
            source="from_user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>

          <ReferenceField
            label="To User"
            source="to_user_id"
            reference="User"
            link="show"
          >
            <FullNameField />
          </ReferenceField>

          <ReferenceField
            label="Organization"
            source="organization_id"
            reference="Organization"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Container"
            source="container_id"
            reference="Container"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>

          <TextField source="scope" />
          <TextField source="subject" />
          <TextField source="body" />

          <TextField source="cta_object_type" label="CTA Object Type" />
          <TextField source="cta_object_id" label="CTA Object ID" />
          <UrlField source="url" />

          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
          <DateField source="deleted_at" showTime />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default NotificationShow;
