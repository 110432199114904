import { gql } from "@apollo/client";

export const USERS_LIST = gql`
  query allUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: UserFilter
  ) {
    items: allUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      account_state
      first_name
      last_name
      company
      bio
      title
      phone
      phone_confirmed
      phone_confirmed_at
      sms_silenced
      sms_sleep_start
      sms_sleep_end
      email
      email_verified
      time_zone
      current_organization_id
      home_container_id
      created_at
      updated_at
      last_access_time
      admin_level
      eula_accepted_at
      role {
        id
        name
      }
      __typename
    }
    total: _allUsersMeta(page: $page, perPage: $perPage, filter: $filter) {
      count
      __typename
    }
  }
`;

export const USERS_ONE = gql`
  query User($id: ID!) {
    data: User(id: $id) {
      id
      account_state
      first_name
      last_name
      company
      bio
      title
      phone
      phone_confirmed
      phone_confirmed_at
      sms_silenced
      sms_sleep_start
      sms_sleep_end
      email
      email_verified
      time_zone
      current_organization_id
      home_container_id
      eula_accepted_at
      admin_level
      created_at
      updated_at
      last_access_time
      role {
        id
        name
      }
      __typename
    }
  }
`;
