import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import React from "react";

import { Button } from "@mui/material";
import DriveFileRenameOutline from "@mui/icons-material/DriveFileRenameOutline";

import { Form, required, useNotify, useRefresh } from "react-admin";
import MuiTextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'
import { useMutation } from 'react-query';

import {
    useRecordContext, useDataProvider,
} from "react-admin";


const TextInput = withStyles({
    root: {
        margin: '16px 0px'
    }
})(MuiTextField);

const defaultSubscription = {
    submitting: true,
    pristine: true,
    valid: true,
    invalid: true,
};

const RenameContainerButton = (props) => {
    const [open, setOpen] = React.useState(false); // Controls modal
    const [version, setVersion] = React.useState(0);
    const [name, setName] = React.useState(null);
    const notify = useNotify(); // Initialize notify object to send notification to dashboard
    const refresh = useRefresh();

    const record = useRecordContext();
    const dataProvider = useDataProvider();

    const { mutate, isLoading } = useMutation(
        ['Container', 'update', { id: record.id, data: { name } }],
        () => dataProvider.update('Container', { id: record.id, data: { name } }),
        { onSuccess: (data) => { refresh(); setOpen(false); notify('container.response.renamed'); } }
    );

    React.useEffect(() => {
        setName(record.name);
    }, [record]);

    const handleSubmit = async values => {
        mutate();
        setOpen(false);
        refresh();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Rename Container: {record.name} </DialogTitle>
                <DialogContent>
                    <Form>
                        <TextInput
                            key={version}
                            label="New Name"
                            source="name"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            validate={[required()]}
                        />
                    </Form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={() => handleSubmit(false)}>Rename</Button>
                </DialogActions>
            </Dialog>

            <Button variant="text" color="primary" aria-label="create" onClick={() => setOpen(true)}>
                {/* this might not be the best approach but we do not want people to be able to move the top container */}
                <DriveFileRenameOutline style={{ marginRight: '4px' }} /> Rename
            </Button>

        </>

    );
};

export default RenameContainerButton;  
