import React from "react";
import {
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  ReferenceField,
  EditButton,
  Pagination,
} from "react-admin";

import ListAside from "./ListAside";
import IdAndNameField from "../IdAndNameField";

const CausalChainsSummaryFilters = [
  <TextInput label="Container ID" source="container_id" defaultValue="" />,
];

const CausalChainsSummaryPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

const CausalChainsSummaryList = ({ permissions, ...props }) => (
  <List
    {...props}
    // filters={CausalChainsSummaryFilters}
    sort={{
      field: "created_at",
      order: "ASC",
    }}
    aside={<ListAside />}
    pagination={<CausalChainsSummaryPagination />}
  >
    <Datagrid optimized bulkActionButtons={false}>
      <TextField source="id" />

      <ReferenceField label="Organization" source="container_id" reference="Container" linkType={false}>
        <SubReference source="organization_id" reference="Organization">
          <IdAndNameField />
        </SubReference>
      </ReferenceField>

      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <IdAndNameField />
      </ReferenceField>

      <TextField source="status" />
      <TextField source="periodical" />
      <DateField source="created_at" showTime />

      <EditButton label="Review" />
    </Datagrid>
  </List>
);

export default CausalChainsSummaryList;
