import React from "react";
import ReactJson from "react-json-view";
import { FormHelperText } from "@mui/material";
import { InputHelperText, Labeled, useInput } from "react-admin";

const JsonInput = (props) => {
  const {
    onChange,
    onBlur,
    source,
    label,
    helperText,
    jsonString = false,
    reactJsonOptions,
  } = props;

  const {
    field,
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({
    // Pass the event handlers to the hook but not the component as the field property already has them.
    // useInput will call the provided onChange and onBlur in addition to the default needed by react-hook-form.
    onChange,
    onBlur,
    ...props,
  });

  const change = (updatedSrc) => {
    let updatedValue = updatedSrc;

    if (jsonString) {
      updatedValue =
        Object.keys(updatedSrc).length === 0
          ? null
          : JSON.stringify(updatedSrc);
    }

    field.onChange(updatedValue);
  };

  const onEdit = (edit) => {
    change(edit.updated_src);

    if (reactJsonOptions?.onEdit) {
      reactJsonOptions.onEdit(edit);
    }
  };

  const onAdd = (add) => {
    change(add.updated_src);

    if (reactJsonOptions?.onAdd) {
      reactJsonOptions.onAdd(add);
    }
  };

  const onDelete = (del) => {
    change(del.updated_src);

    if (reactJsonOptions?.onDelete) {
      reactJsonOptions.onDelete(del);
    }
  };

  let src = field.value;

  // what's this do?
  if (jsonString) {
    src = field.value ? JSON.parse(field.value) : field.value;
  }

  return (
    <div>
      <Labeled source={source} label={label} isRequired={isRequired}>
        <ReactJson
          {...reactJsonOptions}
          src={src || {}}
          onEdit={reactJsonOptions?.onEdit === false ? false : onEdit}
          onAdd={reactJsonOptions?.onAdd === false ? false : onAdd}
          onDelete={reactJsonOptions?.onDelete === false ? false : onDelete}
        />
      </Labeled>
      <FormHelperText error={!!(isTouched && error)}>
        <InputHelperText
          touched={!!isTouched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </div>
  );
};

export default JsonInput;
