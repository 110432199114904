/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { useRecordContext } from "react-admin";

const IdAndNameField = (props) => {
  const record = useRecordContext(props);
  return (
    <span>
      #{record[props.idField]}: {record[props.nameField]}
    </span>
  );
};

IdAndNameField.defaultProps = {
  label: "Name",
  idField: "id",
  nameField: "name",
  addLabel: true,
};

export default IdAndNameField;
